import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AddCanister } from 'ui';

import { useAuth } from '../../../../contexts/AuthContext';
import { addNewCanister, updateUserBalance, fetchAllCanisters } from '../../../../actions';

const AddCanisterModal = (props) => {
  const {
    closeModal,
    subnets,
  } = props;

  const { projectActor } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [user] = useSelector((state) => ([
    state.user,
  ]));

  // TODO: handle availableCycles logic gracefully
  const availableCycles = user?.user?.balance || 0;
  const [loading, setLoading] = useState(false);

  const onAddCanister = async (canister) => {
    setLoading(true);
    dispatch(addNewCanister({
      canister,
      onSuccess: async () => {
        setLoading(false);
        closeModal();
        dispatch(updateUserBalance());
        dispatch(fetchAllCanisters({ t, projectActor }));
      },
      onFailure: () => {
        setLoading(false);
      },
      projectActor,
    }));
  };

  return (
    <AddCanister
      closeModal={closeModal}
      title={t('modals.addCanister.title')}
      description={t('modals.addCanister.subtitle')}
      canisterNameLabel={t('modals.addCanister.canisterName')}
      wasmFileLabel={t('modals.addCanister.wasmFile')}
      updateLabel={t('common.update')}
      removeLabel={t('common.remove')}
      updateFileLabel={t('modals.addCanister.uploadFilel1')}
      dragAndDropLabel={t('modals.addCanister.uploadFilel2')}
      subnetLabel={t('common.subnet')}
      subnets={subnets}
      cyclesLabel={t('modals.addCanister.initialCycles')}
      handleAddCanister={onAddCanister}
      xtcLabel={t('common.xtc')}
      availableBalLabel={t('modals.depositCycles.availableBalance')}
      availableCycles={availableCycles}
      confirmModalBtnTitle={t('common.deposit')}
      cancelModalBtnTitle={t('common.cancel')}
      loading={loading}
    />
  );
};

AddCanisterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  subnets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default AddCanisterModal;
