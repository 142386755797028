// This is a template extracted from dfx generated stubs
// canisterium is going to be replaced with lower case name eg: canisterium
// CANISTERIUM is going to be replaced with upper case name eg: CANISTERIUM

import { Actor, HttpAgent } from "@dfinity/agent";
// Imports and re-exports candid interface
import { idlFactory } from './canisterium.did.js';
export { idlFactory } from './canisterium.did.js';
// CANISTER_ID is replaced by webpack based on node environment
export const canisterId = process.env.CANISTERIUM_CANISTER_ID;

export const createActor = (canisterId, options) => {
  const agent = new HttpAgent({ ...options?.agentOptions });

  // Fetch root key for certificate validation during development
  if(process.env.NODE_ENV !== "production") {
    agent.fetchRootKey().catch(err=>{
      console.warn("Unable to fetch root key. Check to ensure that your local replica is running");
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  return Actor.createActor(idlFactory, {
    agent,
    canisterId,
    ...options?.actorOptions,
  });
};

export const canisterium = createActor(canisterId);
