import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import currency from 'currency.js';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useTranslation } from 'react-i18next';

import {
  Button,
  InputBase,
  Select,
  MenuItem,
} from 'ui';

// TODO: replace with fleekon/icons version
// import batteryEnabled from './battery-enabled.svg';

const defaultMaskOptions = {
  prefix: '$',
  suffix: ' USD',
  integerLimit: 7,
  decimalLimit: 2,
  allowDecimal: true,
  decimalSymbol: '.',
  allowNegative: false,
  allowLeadingZeroes: false,
  thousandsSeparatorSymbol: ',',
  includeThousandsSeparator: true,
};

const AddCycles = (props) => {
  const {
    closeModal,
    cycleBalance,
    canisters,
  } = props;

  const { t } = useTranslation();

  const currencyMask = createNumberMask(defaultMaskOptions);
  const [cyclesAmount, setCyclesAmount] = useState(0.00);
  const [selectedCanister, setSelectedCanister] = useState(canisters[0].canisterId);

  const addCycles = () => {
    // TODO add integration to addCycles to canister using the canister Id
    /* eslint-disable-next-line */
    console.log(`Add ${cyclesAmount} TC to canister`, selectedCanister);
    closeModal();
  };

  const handleChange = (value) => {
    const parsedAmount = value.replace('$', '').replace(' USD', '');
    const amount = parsedAmount === '' ? '0' : parsedAmount;
    setCyclesAmount(amount);
  };

  const canisterOptions = canisters.map((canister) => (
    <MenuItem key={canister.canisterId} value={canister.canisterId}>
      <div className="flex flex-row items-center">
        {/* <img className="h-4 mr-1" src={batteryEnabled} alt="canister-icon" /> */}
        <span className="mr-1 font-medium">{canister.canisterName}</span>
        <span>({canister.canisterId})</span>
      </div>
    </MenuItem>
  ));

  return (
    <>
      <div className="mb-4 text-center md:text-left">
        <h1 className="font-medium text-lg leading-6 text-gray-900">
          {t('modals.addCycles.title')}
        </h1>
        <p className="mt-2 text-gray-500 leading-5 text-sm">
          {t('modals.addCycles.description')}
        </p>
      </div>
      <div className="mb-5">
        <p className="text-sm font-medium text-gray-700 mb-1">
          {t('modals.addCycles.addToLabel')}
        </p>
        <Select
          value={selectedCanister}
          onChange={(value) => setSelectedCanister(value)}
        >
          {canisterOptions}
        </Select>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-700 mb-1">
          {t('modals.addCycles.cyclesAmountLabel')}
        </p>
        <InputBase
          id="cycles"
          data-testid="cycles"
          type="text"
          name="cycles"
          // TODO: InputBase should take number
          value={typeof cyclesAmount === 'number' ? String(cyclesAmount) : cyclesAmount}
          placeholder="0.0"
          mask={currencyMask}
          className="font-normal"
          component={MaskedInput}
          onChange={(e) => handleChange(e.target.value)}
          innerLabel={`${currency(cyclesAmount).format({ symbol: '' })} TC`}
        />
        <p className="text-sm font-medium text-gray-700 mb-1">
          {t('modals.addCycles.cyclesAmountSubLabel')}
          <span className="text-green-800">
            {` $${cycleBalance} (${cycleBalance} TC)`}
          </span>
        </p>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-end">
        <Button className="mt-3 md:mx-3 md:mt-0 font-medium" data-testid="close-modal" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
        <Button
          className="font-medium"
          variant="primary"
          onClick={addCycles}
          disabled={parseFloat(cyclesAmount) === 0}
        >
          {t('modals.addCycles.addCycles')}
        </Button>
      </div>
    </>
  );
};

AddCycles.propTypes = {
  canisters: PropTypes.arrayOf(
    PropTypes.shape({
      canisterId: PropTypes.string.isRequired,
      canisterName: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  closeModal: PropTypes.func.isRequired,
  cycleBalance: PropTypes.string.isRequired,
};

export default AddCycles;
