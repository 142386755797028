import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DepositCycles } from 'ui';

import { useAuth } from '../../../../contexts/AuthContext';
import { transferXTCcycles, updateUserBalance, getCanisterBalanceById } from '../../../../actions';

const DepositCyclesModal = (props) => {
  const {
    id,
    name,
    canisters,
    closeModal,
  } = props;
  const { projectActor } = useAuth();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [user] = useSelector((state) => ([
    state.user,
  ]));

  // TODO: handle availableCycles logic gracefully
  const availableCycles = user?.user?.balance || 0;
  const [loading, setLoading] = useState(false);

  const onDeposit = async (canisterId, cyclesToken) => {
    setLoading(true);
    dispatch(transferXTCcycles({
      depositDetails: {
        canisterId,
        cyclesToken,
      },
      onSuccess: async () => {
        setLoading(false);
        closeModal();
        dispatch(updateUserBalance());
        dispatch(getCanisterBalanceById({ canisterId, projectActor }));
      },
      onFailure: () => {
        setLoading(false);
      },
    }));
  };

  return (
    <DepositCycles
      closeModal={closeModal}
      title={t('modals.depositCycles.title')}
      description={t('modals.depositCycles.description')}
      depositToLabel={t('modals.depositCycles.depositTo')}
      cyclesLabel={t('common.cycles')}
      handleDepositCycles={onDeposit}
      xtcLabel={t('common.xtc')}
      availableBalLabel={t('modals.depositCycles.availableBalance')}
      availableCycles={availableCycles}
      confirmModalBtnTitle={t('common.deposit')}
      cancelModalBtnTitle={t('common.cancel')}
      loading={loading}
      selectedCanisterFromList={{
        id,
        name,
      }}
      canisters={canisters}
    />
  );
};

DepositCyclesModal.defaultProps = {
  canisters: [],
};

DepositCyclesModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  canisters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default DepositCyclesModal;
