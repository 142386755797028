import React from 'react';
import PropTypes from 'prop-types';

import { SimpleCard } from 'ui';

import { styled } from '@stitches/react';

import vol1 from './assets/vol1.svg';
import vol2 from './assets/vol2.svg';
import vol3 from './assets/vol3.svg';

const ELEMENTS = [
  {
    id: 'started',
    link: 'https://docs.fleek.ooo/getting-started/creating-an-account/',
    imgSrc: vol1,
  },
  {
    id: 'docs',
    link: 'https://docs.fleek.ooo/',
    imgSrc: vol2,
  },
  {
    id: 'ghub',
    link: 'https://github.com/psychedelic/fleek-ooo',
    imgSrc: vol3,
  },
];

const SimpleCardContainer = styled(SimpleCard, {
  width: '220px',

  '& .docs-card-item': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

function DocsCard({ t }) {
  return (
    <SimpleCardContainer className="docs-card self-center">
      <ol className="flex flex-col -my-1">
        {
          ELEMENTS.map(({ id, link, imgSrc }) => (
            <li key={id} className="docs-card-item flex flex-row py-1">
              <img src={imgSrc} height={18} width={18} alt={id} />
              <a className="ml-2" href={link} target="_blank" rel="noreferrer">
                {t(`docs_card.${id}`)}
              </a>
            </li>
          ))
        }
      </ol>
    </SimpleCardContainer>
  );
}

DocsCard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default DocsCard;
