import { BaseModal } from 'ui';
import { Transition } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@stitches/react';

import { useAuth } from '../../contexts/AuthContext';
import { isConnectedToPlug } from '../../utils/plug';

import modals from './modals';
import { closeModal } from './actions';

const ModalContainer = styled(BaseModal, {
  maxHeight: '800px',
});

const Modal = () => {
  const {
    open,
    size,
    modal,
    props: modalProps,
  } = useSelector((state) => state.modal);
  const { plugConnectionStatus } = useAuth();

  const dispatch = useDispatch();

  const ModalContent = modals[modal] || 'div';

  const closeModalHandler = () => dispatch(closeModal());

  if (!modal) return null;

  return (
    <Transition show={open}>
      <div className="fixed z-10 inset-0 overflow-y-auto justify-center items-center flex">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute inset-0"
          onClick={isConnectedToPlug(plugConnectionStatus) ? closeModalHandler : () => {}}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </Transition.Child>

        <div className={`sm:align-middle sm:max-w-${size} sm:w-full p-6 sm:p-0`}>
          <ModalContainer
            className={`sm:max-w-${size} overflow-y-auto inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6`}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            component={Transition.Child}
            onClick={(e) => e.stopPropagation()}
          >
            <ModalContent closeModal={closeModalHandler} {...modalProps} />
          </ModalContainer>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
