import './index.css';
import 'tippy.js/dist/tippy.css';

import React from 'react';
import i18n from 'i18next';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';

import App from './App';
import localesConfig from './locales';
// import reportWebVitals from './reportWebVitals';

i18n.use(initReactI18next).init(localesConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
