import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@stitches/react';

import {
  TextCell,
  NameCell,
  TypeCell,
  ActionCell,
  AddressCell,
} from 'ui';

const TableRow = styled('tr', {
  '& > td': {
    height: '52px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  variants: {
    disabled: {
      true: {
        backgroundColor: '#F7F7F7',
      },
    },
  },
});

const RenderRow = ({
  i18n,
  data,
  disabled,
  menuOptions,
  onActionClickOption,
}) => {
  const {
    id,
    name,
    type,
    status,
    network,
    created,
    memoryUsage,
    chargingStationId,
    highlighted,
    balance,
  } = data;

  const history = useHistory();

  return (
    <TableRow
      onDoubleClick={() => history.push(`canisters/${id}`)}
      className={clsx(
        'border-t border-gray-200 render-row',
        highlighted && 'animate-new-row',
      )}
      disabled={disabled}
    >
      <NameCell name={name} status={status} disabled={disabled} />
      <AddressCell
        address={id}
        network={network}
        chargingStation={chargingStationId}
      />
      <TextCell textContent={`${balance} TC`} secondaryContent={`$${balance}`} align="right" boldText />
      <TextCell textContent={memoryUsage} align="right" boldText />
      <TextCell textContent={created} align="right" boldText />
      <TypeCell>{type}</TypeCell>
      <ActionCell
        chargingStation={{
          title: i18n.chargingStation,
          address: chargingStationId,
          disabled: !chargingStationId,
        }}
        menuOptions={menuOptions(data)}
        onClickOption={(closeMenu, optionId) => onActionClickOption(optionId, data, closeMenu)}
      />
    </TableRow>
  );
};

RenderRow.defaultProps = {
  menuOptions: () => {},
  disabled: false,
};

RenderRow.propTypes = {
  onActionClickOption: PropTypes.func.isRequired,
  menuOptions: PropTypes.func,
  disabled: PropTypes.bool,
  i18n: PropTypes.shape({
    chargingStation: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['running', 'stopped']),
    id: PropTypes.string,
    chargingStationId: PropTypes.string,
    network: PropTypes.string,
    cyclesUsed: PropTypes.string,
    balance: PropTypes.string,
    totalTx: PropTypes.string,
    updated: PropTypes.string,
    type: PropTypes.string,
    created: PropTypes.string,
    memoryUsage: PropTypes.number,
    highlighted: PropTypes.bool,
  }).isRequired,
};

export default RenderRow;
