import { plugStatusCodes } from '@constants';

export default {};

export const isPlugInstalled = (global = window) => (global?.ic && typeof global.ic?.plug === 'object') || false;

export const isConnectedToPlug = (currentConnectionStatus) => (
  plugStatusCodes.connected === currentConnectionStatus
);

export const isConnectionToPlugFailed = (currentConnectionStatus) => (
  plugStatusCodes.failedToConnect === currentConnectionStatus
);
