export const idlFactory = ({ IDL }) => {
  const AddExistingCanisterRequest = IDL.Record({
    'name' : IDL.Text,
    'canister_id' : IDL.Principal,
    'canister_type' : IDL.Text,
  });
  const CanisterMutateResult = IDL.Record({
    'code' : IDL.Opt(IDL.Text),
    'is_error' : IDL.Bool,
    'message' : IDL.Opt(IDL.Text),
  });
  const DefiniteCanisterSettings = IDL.Record({
    'freezing_threshold' : IDL.Nat,
    'controllers' : IDL.Vec(IDL.Principal),
    'memory_allocation' : IDL.Nat,
    'compute_allocation' : IDL.Nat,
  });
  const CreateCanisterRequest = IDL.Record({
    'controller' : IDL.Opt(IDL.Vec(IDL.Principal)),
    'wasm_module' : IDL.Vec(IDL.Nat8),
    'cycles' : IDL.Nat64,
    'canister_name' : IDL.Text,
    'canister_type' : IDL.Text,
  });
  const CreateCanisterResponse = IDL.Record({
    'code' : IDL.Opt(IDL.Text),
    'data' : IDL.Opt(IDL.Record({ 'canister_id' : IDL.Principal })),
    'is_error' : IDL.Bool,
    'message' : IDL.Opt(IDL.Text),
  });
  const CanisterInfo = IDL.Record({
    'name' : IDL.Text,
    'canister_id' : IDL.Principal,
    'canister_type' : IDL.Text,
  });
  const CanisterResult = IDL.Record({
    'code' : IDL.Opt(IDL.Text),
    'data' : IDL.Opt(CanisterInfo),
    'is_error' : IDL.Bool,
    'message' : IDL.Opt(IDL.Text),
  });
  const CanisterListResult = IDL.Record({
    'code' : IDL.Opt(IDL.Text),
    'data' : IDL.Opt(IDL.Vec(CanisterInfo)),
    'is_error' : IDL.Bool,
    'message' : IDL.Opt(IDL.Text),
  });
  const CanisterSettings = IDL.Record({
    'freezing_threshold' : IDL.Opt(IDL.Nat),
    'controllers' : IDL.Opt(IDL.Vec(IDL.Principal)),
    'memory_allocation' : IDL.Opt(IDL.Nat),
    'compute_allocation' : IDL.Opt(IDL.Nat),
  });
  return IDL.Service({
    'add_existing_canister' : IDL.Func(
        [AddExistingCanisterRequest],
        [CanisterMutateResult],
        [],
      ),
    'canister_status' : IDL.Func(
        [IDL.Record({ 'canister_id' : IDL.Principal })],
        [
          IDL.Record({
            'code' : IDL.Opt(IDL.Text),
            'data' : IDL.Opt(
              IDL.Record({
                'status' : IDL.Variant({
                  'stopped' : IDL.Null,
                  'stopping' : IDL.Null,
                  'running' : IDL.Null,
                }),
                'memory_size' : IDL.Nat,
                'cycles' : IDL.Nat,
                'settings' : DefiniteCanisterSettings,
                'module_hash' : IDL.Opt(IDL.Vec(IDL.Nat8)),
              })
            ),
            'is_error' : IDL.Bool,
            'message' : IDL.Opt(IDL.Text),
          }),
        ],
        [],
      ),
    'create_canister' : IDL.Func(
        [CreateCanisterRequest],
        [CreateCanisterResponse],
        [],
      ),
    'delete_canister' : IDL.Func([IDL.Principal], [CanisterMutateResult], []),
    'get_by_canister_id' : IDL.Func([IDL.Principal], [CanisterResult], []),
    'list_canisters' : IDL.Func([], [CanisterListResult], []),
    'update_canister_name' : IDL.Func(
        [IDL.Principal, IDL.Text],
        [CanisterMutateResult],
        [],
      ),
    'update_canister_type' : IDL.Func(
        [IDL.Principal, IDL.Text],
        [CanisterMutateResult],
        [],
      ),
    'update_settings' : IDL.Func(
        [
          IDL.Record({
            'canister_id' : IDL.Principal,
            'settings' : CanisterSettings,
          }),
        ],
        [CanisterMutateResult],
        [],
      ),
    'whoami' : IDL.Func([], [IDL.Principal], []),
  });
};
export const init = ({ IDL }) => { return []; };
