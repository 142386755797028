import React from 'react';
import PropTypes from 'prop-types';
import { ConnectToPlug } from 'ui';
import { useTranslation } from 'react-i18next';
import config from '../../../../config';
import { isPlugInstalled } from '../../../../utils/plug';

import { useAuth } from '../../../../contexts/AuthContext';

const ConnectToPlugModal = ({
  closeModal,
}) => {
  const { handleConnectToPlug } = useAuth();
  const { t } = useTranslation();
  const hasPlug = isPlugInstalled();

  const handleConnectCallback = () => {
    handleConnectToPlug();
    closeModal();
  };

  return (
    <ConnectToPlug
      closeModal={closeModal}
      handleConnectToPlug={handleConnectCallback}
      title={t('modals.connectToPlug.title')}
      description={t('modals.connectToPlug.description')}
      supported={t('modals.connectToPlug.supported')}
      hasPlug={hasPlug}
      whitelist={[config.canisterId]}
      host={config.host}
    />
  );
};

ConnectToPlugModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ConnectToPlugModal;
