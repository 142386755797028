import React, { useState } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import copy from 'copy-to-clipboard';
import MaskedInput from 'react-text-mask';
import {
  CodeWindow,
  InputBase,
  Button,
  Icon,
  IconNames,
} from 'ui';
import { useTranslation, Trans } from 'react-i18next';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { defaultMaskOptions, docsURL } from '../contants';

// eslint-disable-next-line quotes
const CODE = `dfx canister --network ic call "zwigo-aiaaa-aaaaa-qaa3a-cai" authorize '(principal "chetu-3igui-2ub25-oblyz-in4om--eybjo-inqcg-w5rer-eqe")' uthorize '(principal "chetu-3igui-2ub25-uthorize '(principal).`;

/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */
const AddCode = (props) => {
  const {
    onCancel,
    onSubmit,
    threshold: initialThreshold,
    refillAmount: initialRefillAmount,
  } = props;

  const { t } = useTranslation();
  const currencyMask = createNumberMask(defaultMaskOptions);

  const [threshold, setThreshold] = useState(initialThreshold);
  const [refillAmount, setRefillAmount] = useState(initialRefillAmount);

  const onCopy = () => copy(CODE);

  const handleSubmit = () => {
    const data = {
      refillAmount: currency(refillAmount).value,
      refillThreshold: currency(threshold).value,
    };

    onSubmit(data);
  };

  return (
    <>
      <div className="text-lg text-gray-900 font-medium mt-6">
        {t('modals.addChargingStation.steps.addCode.title')}
      </div>
      <div className="text-sm text-gray-500 mt-2">
        <Trans
          components={{ a: <a href={docsURL} target="_blank" rel="noreferrer" className="text-blue-800" /> }}
        >
          {t('modals.addChargingStation.steps.addCode.subtitle')}
        </Trans>
      </div>
      <CodeWindow
        onCopy={onCopy}
        className="mt-5"
        copyText={t('common.copy')}
      >
        $ {CODE}
      </CodeWindow>
      <div className="bg-blue-50 rounded-md flex p-4 mt-6">
        <Icon
          icon={IconNames.infoCircle}
          className="text-blue-400 mt-1"
          size="14px"
        />
        <div className="text-sm text-blue-800 font-medium ml-4">
          {t('modals.addChargingStation.steps.addCode.info')}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.addChargingStation.steps.chargingStation.form.refillThreshold.label')}
            <Icon
              icon={IconNames.infoCircle}
              className="ml-2"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              data-testid="cycles-field-one"
              type="text"
              name="cycles"
              value={threshold}
              placeholder="0.0"
              mask={currencyMask}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setThreshold(e.target.value)}
              innerLabel={`${currency(threshold).format({ symbol: '' })} IC`}
            />
          </div>
        </div>

        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.addChargingStation.steps.chargingStation.form.refillAmount.label')}
            <Icon
              icon={IconNames.infoCircle}
              className="ml-2"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              type="text"
              name="cycles"
              data-testid="cycles-field-two"
              placeholder="0.0"
              mask={currencyMask}
              value={refillAmount}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setRefillAmount(e.target.value)}
              innerLabel={`${currency(refillAmount).format({ symbol: '' })} IC`}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button className="w-full sm:ml-3 sm:w-auto" type="button" data-testid="submit-button" variant="primary" onClick={handleSubmit}>
          {t('modals.addChargingStation.submit')}
        </Button>
        <Button className="mt-3 w-full sm:mt-0 sm:w-auto" type="button" data-testid="cancel-button" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

AddCode.defaultProps = {
  threshold: '$0.00 USD',
  refillAmount: '$ 0.00 USD',
};

AddCode.propTypes = {
  threshold: PropTypes.string,
  refillAmount: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddCode;
