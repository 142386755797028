import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { styled } from '@stitches/react';

import {
  TextCell,
  NameCell,
  ActionCell,
  AddressCell,
} from 'ui';

const TableRow = styled('tr', {
  '& > td': {
    height: '52px',
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const RenderCSRow = ({
  data,
  menuOptions,
  onActionClickOption,
}) => {
  const {
    id,
    name,
    status,
    network,
    balance,
    lastRefill,
    cyclesUsed,
    refillAt,
    amount,
    chargingStationId,
  } = data;

  const history = useHistory();

  const refillAmount = `$${refillAt} / $${amount}`;

  return (
    <TableRow
      className="border-t border-gray-200"
      onDoubleClick={() => history.push(`charging-station/${id}`)}
    >
      <NameCell name={name} status={status} />
      <AddressCell
        address={id}
        network={network}
        chargingStation={chargingStationId}
      />
      <TextCell textContent={`${cyclesUsed} TC`} secondaryContent={`$${cyclesUsed}`} align="right" boldText />
      <TextCell textContent={`${balance} TC`} secondaryContent={`$${balance}`} align="right" boldText />
      <TextCell textContent={lastRefill} align="right" boldText />
      <TextCell textContent={refillAmount} align="right" boldText />
      <ActionCell
        menuOptions={menuOptions(data)}
        onClickOption={(closeMenu, optionId) => onActionClickOption(optionId, data, closeMenu)}
      />
    </TableRow>
  );
};

RenderCSRow.defaultProps = {
  menuOptions: () => {},
};

RenderCSRow.propTypes = {
  onActionClickOption: PropTypes.func.isRequired,
  menuOptions: PropTypes.func,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['running', 'stopped']),
    id: PropTypes.string,
    chargingStationId: PropTypes.string,
    network: PropTypes.string,
    cyclesUsed: PropTypes.string,
    balance: PropTypes.string,
    totalTx: PropTypes.string,
    updated: PropTypes.string,
    lastRefill: PropTypes.string,
    refillAt: PropTypes.string,
    type: PropTypes.string,
    amount: PropTypes.string,
  }).isRequired,
};

export default RenderCSRow;
