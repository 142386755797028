import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChangeCanisterField } from 'ui';
import { UPDATE_CANISTER_BY_ID } from '@reducers/canisters';
import { useAuth } from '../../../../contexts/AuthContext';
import { updateCanisterType } from '../../../../actions';

const ChangeCanisterTypeModal = (props) => {
  const { closeModal, id, type } = props;
  const { projectActor } = useAuth();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onUpdateCanisterType = (updatedCanisterType) => {
    setLoading(true);
    dispatch(updateCanisterType({
      projectActor,
      canister: { id, type: updatedCanisterType },
      onSuccess: () => {
        // update canister in store
        dispatch({
          type: UPDATE_CANISTER_BY_ID,
          canisterId: id,
          canister: {
            type: updatedCanisterType,
          },
        });
        setLoading(false);
        closeModal();
      },
      onFailure: () => {
        setLoading(false);
      },
    }));
  };

  return (
    <ChangeCanisterField
      closeModal={closeModal}
      handleUpdateCanisterField={onUpdateCanisterType}
      title={t('modals.updateCanisterType.title')}
      description={t('modals.updateCanisterType.subtitle')}
      canisterFieldLabel={t('modals.updateCanisterType.canisterType')}
      canisterFieldPlaceholder={t('modals.updateCanisterType.canisterType')}
      confirmModalBtnTitle={t('common.confirm')}
      cancelModalBtnTitle={t('common.cancel')}
      canisterField={type}
      loading={loading}
    />
  );
};

ChangeCanisterTypeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ChangeCanisterTypeModal;
