import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteCanister } from 'ui';
import { REMOVE_CANISTER_BY_ID } from '@reducers/canisters';
import { useAuth } from '../../../../contexts/AuthContext';
import { deleteCanisterById } from '../../../../actions';

const DeleteCanisterModal = (props) => {
  const { id, name, closeModal } = props;
  const { projectActor } = useAuth();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onDeleteCanister = (canisterId) => {
    setLoading(true);
    dispatch(deleteCanisterById({
      projectActor,
      canisterId,
      onSuccess: () => {
        // delete canister from store
        dispatch({
          canisterId,
          type: REMOVE_CANISTER_BY_ID,
        });
        setLoading(false);
        closeModal();
      },
      onFailure: () => {
        // TODO: inform the end-user that it failed

        setLoading(false);
      },
    }));
  };

  return (
    <DeleteCanister
      closeModal={closeModal}
      handleDeleteCanister={onDeleteCanister}
      title={t('modals.deleteCanister.title')}
      description={t('modals.deleteCanister.description')}
      canisterName={name}
      canisterId={id}
      confirmModalBtnTitle={t('modals.deleteCanister.action')}
      cancelModalBtnTitle={t('common.cancel')}
      loading={loading}
    />
  );
};

DeleteCanisterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeleteCanisterModal;
