import React from 'react';
import PropTypes from 'prop-types';

import { TableHeader } from 'ui';

/**
 * @typedef {import('../TableHeader').TableHeaderProps} TableHeaderProps
 * @typedef {Object} RenderHeadersProps
 * @property {Array.<TableHeaderProps>} headers
 *
 * @param {ActivityListProps} props
 * @returns {JSX.Element}
*/
const RenderHeaders = ({ headers }) => {
  const headerComponents = headers.map((header) => <TableHeader key={`header-${header.label}`} className="sticky top-0 z-20" {...header} />);

  return (
    <tr>
      {headerComponents}
    </tr>
  );
};

RenderHeaders.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      ...TableHeader.propTypes,
    }),
  ).isRequired,
};

export default RenderHeaders;
