import { TOAST_NOTIFICATIONS_ACTIONS } from '@reducers/toast-notifications';

export default {};

export const getDangerToastDispatchObj = (description) => ({
  type: TOAST_NOTIFICATIONS_ACTIONS.ADD_TOAST,
  toast: {
    id: new Date().getTime(),
    show: true,
    status: 'danger',
    description,
  },
});
