import { useEffect, useState } from 'react';
import { PAGINATION_MAX } from '@constants';

const usePagination = ({
  fetchMore,
  total,
  fetchedDataAmount,
  data,
}) => {
  const [currentPaginationIndex, setCurrentPaginationIndex] = useState(0);

  const onNext = () => {
    setCurrentPaginationIndex(currentPaginationIndex + PAGINATION_MAX);
  };
  const onPrevious = () => {
    setCurrentPaginationIndex(currentPaginationIndex - PAGINATION_MAX);
  };

  const getToValue = () => {
    const to = currentPaginationIndex + PAGINATION_MAX;
    if (to >= total) {
      return total;
    }
    return to;
  };

  const getShownData = () => (
    data.slice(currentPaginationIndex, currentPaginationIndex + PAGINATION_MAX)
  );

  useEffect(() => {
    const maxDisplayedRowIndex = currentPaginationIndex + PAGINATION_MAX - 1;
    if (maxDisplayedRowIndex > fetchedDataAmount - 1) {
      fetchMore({
        first: PAGINATION_MAX,
        offset: currentPaginationIndex,
      });
    }
  }, [currentPaginationIndex]);

  return {
    onNext,
    onPrevious,
    currentPaginationIndex,
    PAGINATION_MAX,
    getToValue,
    getShownData,
  };
};

export default usePagination;
