import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// TODO: RenderRow and LoadingRow are not generic, make it generic
import { RenderRow, LoadingRow } from './components';
import RenderHeaders from '../RenderHeaders';

const CanisterListOfRows = ({
  i18n,
  data,
  menuOptions,
  onActionClickOption,
}) => {
  const List = data.map((rowData) => (
    <RenderRow
      i18n={i18n}
      data={rowData}
      key={rowData.id}
      menuOptions={menuOptions}
      onActionClickOption={onActionClickOption}
    />
  ));

  return List;
};

const CanistersTable = ({
  i18n,
  data,
  headers,
  className,
  menuOptions,
  onActionClickOption,
  loading,
  maxRows,
}) => {
  const loadingRowsAmount = maxRows > data.lenght ? data.lenght : maxRows;
  const LoadingRows = Array.from(Array(loadingRowsAmount)
    .keys())
    .map((val) => <LoadingRow key={val} />);

  return (
    <table className={clsx('w-full relative', className)}>
      <thead>
        <RenderHeaders headers={headers} />
      </thead>
      <tbody>
        <CanisterListOfRows
          i18n={i18n}
          data={data}
          menuOptions={menuOptions}
          onActionClickOption={onActionClickOption}
        />
        {loading && LoadingRows}
      </tbody>
    </table>
  );
};

CanistersTable.defaultProps = {
  className: '',
  onActionClickOption: () => {},
  loading: false,
  maxRows: 10,
};

CanistersTable.propTypes = {
  className: PropTypes.string,
  onActionClickOption: PropTypes.func,
  menuOptions: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  i18n: PropTypes.shape({
    chargingStation: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  maxRows: PropTypes.number,
};

export default CanistersTable;
