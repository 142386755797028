import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Item = (props) => {
  const {
    children,
    className,
    ...restProps
  } = props;

  return (
    <div
      className={clsx(
        'flex justify-between items-center h-11 text-sm',
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

Item.defaultProps = {
  children: null,
  className: null,
};

Item.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Item;
