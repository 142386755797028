import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@stitches/react';
import cyclesImg from './cycles.svg';

const CyclesCardRoot = styled('div', {
  maxWidth: '346px',
});

const CyclesIcon = styled('img', {
  boxShadow: '0px 0px 0px rgba(6, 44, 82, 0.1), 0px 1px 3px rgba(64, 66, 69, 0.12), 0px 2px 16px rgba(33, 43, 54, 0.08)',
});

const BalanceCard = (props) => {
  const {
    i18n,
    onClick,
    mainText,
    className,
    secondaryText,
    ...restProps
  } = props;

  return (
    <CyclesCardRoot
      className={clsx(
        'cycles-card-root',
        className,
      )}
      {...restProps}
    >
      <div className="flex justify-between">
        <CyclesIcon src={cyclesImg} alt="cycles" className="cycles-icon rounded-full" />
        <button
          type="button"
          onClick={onClick}
          className="add-cycles-button text-sm text-blue-500 font-medium rounded-md border border-blue-500 h-7 px-3 active:text-blue-800 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none"
        >
          {i18n.cta}
        </button>
      </div>
      <div className="text-gray-500 mt-4 text-xs uppercase leading-4 tracking-wider">{i18n.title}</div>
      <div className="text-xl font-bold mt-2">{mainText}</div>
      <div className="text-sm text-gray-500 mt-1">{secondaryText}</div>
    </CyclesCardRoot>
  );
};

BalanceCard.defaultProps = {
  mainText: null,
  className: null,
  secondaryText: null,
};

BalanceCard.propTypes = {
  mainText: PropTypes.string,
  className: PropTypes.string,
  secondaryText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default BalanceCard;
