import React, { useState } from 'react';
import { ChangeCanisterField } from 'ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UPDATE_CANISTER_BY_ID } from '@reducers/canisters';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../../contexts/AuthContext';
import { updateCanisterName } from '../../../../actions';

/**
 * @typedef {Object} ChangeNameProps
 * @property {string} id
 * @property {string} name
 * @property {func} closeModal
 *
 * @param {ChangeNameProps} props
 * @returns {JSX.Element}
 */

const ChangeName = (props) => {
  const { closeModal, id, name } = props;
  const { projectActor } = useAuth();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onUpdateCanisterName = (updatedCanisterName) => {
    setLoading(true);
    dispatch(updateCanisterName({
      projectActor,
      canister: { id, name: updatedCanisterName },
      onSuccess: () => {
        // update canister in store
        dispatch({
          type: UPDATE_CANISTER_BY_ID,
          canisterId: id,
          canister: {
            name: updatedCanisterName,
          },
        });
        setLoading(false);
        closeModal();
      },
      onFailure: () => {
        setLoading(false);
      },
    }));
  };

  return (
    <>
      <ChangeCanisterField
        closeModal={closeModal}
        handleUpdateCanisterField={onUpdateCanisterName}
        title={t('modals.changeCanisterName.title')}
        description={t('modals.changeCanisterName.description')}
        canisterFieldLabel={t('modals.changeCanisterName.canisterName')}
        canisterFieldPlaceholder={t('modals.changeCanisterName.canisterName')}
        confirmModalBtnTitle={t('common.confirm')}
        cancelModalBtnTitle={t('common.cancel')}
        canisterField={name}
        loading={loading}
      />
    </>
  );
};

ChangeName.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ChangeName;
