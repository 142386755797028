import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@stitches/react';

import { Item, Title, Container } from './components';

const Card = styled('div', {
  boxShadow: '0px 1px 11px rgba(164, 164, 164, 0.3)',
});

const BalanceCard = (props) => {
  const {
    title,
    onClick,
    children,
    className,
    buttonText,
    ...restProps
  } = props;

  return (
    <Card
      className={clsx(
        'cycles-info-card-root flex flex-col rounded-lg',
        className,
      )}
      {...restProps}
    >
      <div className="flex justify-between px-4 p-3 items-center">
        <div className="font-semibold text-sm">
          {title}
        </div>
        <button
          type="button"
          onClick={onClick}
          className="info-card-button text-sm text-gray-500 border rounded-md border-gray-400 h-7 px-3 transform hover:scale-105 transition-all ease-in-out duration-300 active:text-gray-700 focus:outline-none"
        >
          {buttonText}
        </button>
      </div>
      {children}
    </Card>
  );
};

BalanceCard.defaultProps = {
  title: null,
  children: null,
  className: null,
  buttonText: null,
  onClick: () => {},
};

BalanceCard.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  buttonText: PropTypes.string,
};

BalanceCard.Item = Item;
BalanceCard.Title = Title;
BalanceCard.Container = Container;

export default BalanceCard;
