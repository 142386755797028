import PropTypes from 'prop-types';

const TestModal = (props) => (
  // eslint-disable-next-line react/destructuring-assignment
  <div>TestModal textProp: {props.text}</div>
);

TestModal.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TestModal;
