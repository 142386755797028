import { combineReducers } from 'redux';

import modalReducer from './modal';
import toastNotificationsReducer from './toast-notifications';
import canistersReducer from './canisters';
import userReducer from './user';

export default combineReducers({
  modal: modalReducer,
  toastNotifications: toastNotificationsReducer,
  canisters: canistersReducer,
  user: userReducer,
});
