export default (t) => [
  {
    id: 'reinstall',
    title: t('modals.upgradeWASM.options.reinstall.title'),
    subtitle: t('modals.upgradeWASM.options.reinstall.subtitle'),
    selected: true,
  },
  {
    id: 'upgrade',
    title: t('modals.upgradeWASM.options.upgrade.title'),
    subtitle: t('modals.upgradeWASM.options.upgrade.subtitle'),
    selected: false,
  },
];
