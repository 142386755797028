import { formatToCycles } from '@utils/formats';
import { validateCanisterId } from '@utils/canister';
import { CYCLES_PER_TC } from '@constants';

export default {};

export const XTC_CURRENCY_ABBR = 'XTC';

export const getXTCAmount = async (global = window) => {
  let XTCAmount = -1;

  try {
    if (!global.ic?.plug) {
      throw Error('Oops! Plug is not available');
    }

    const response = await global.ic?.plug?.requestBalance();

    if (!Array.isArray(response) || !response.length) {
      throw Error('Oops! Plug requestBalance invalid response');
    }

    const XTCData = response
      .filter((obj) => obj?.symbol?.toUpperCase() === XTC_CURRENCY_ABBR)
      .shift();

    if (typeof XTCData?.amount !== 'number') {
      throw Error('Oops! Plug XTC data is not valid.');
    }

    XTCAmount = XTCData?.amount;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(`Oops! Failed to retrieve XTC (${err.message})`);
  }

  return XTCAmount;
};

export const parseDepositDetails = async (depositDetails) => {
  let response = {
    hasError: false,
    errorMessage: '',
    canisterId: '',
    cycles: 0,
  };

  try {
    const {
      hasError: canisterValidationFailed,
      errorMessage: canisterErrorMessage,
    } = await validateCanisterId(depositDetails.canisterId);

    if (canisterValidationFailed) {
      throw Error(canisterErrorMessage);
    }

    const { canisterId } = depositDetails;
    response = { ...response, canisterId };

    const {
      hasError: cyclesTokenValidationFailed,
      errorMessage: cyclesTokenErrorMessage,
      cycles,
    } = await formatToCycles(depositDetails.cyclesToken);

    if (cyclesTokenValidationFailed) {
      throw Error(cyclesTokenErrorMessage);
    }

    response = { ...response, cycles };
  } catch (error) {
    response = { ...response, hasError: true, errorMessage: error.message };
  }

  return response;
};

export const burnXTCCycles = async (depositDetails, global = window) => {
  let response = {
    hasError: false,
    errorMessage: '',
  };

  try {
    if (!global.ic?.plug) {
      throw Error('Oops! Plug is not available');
    }

    const {
      hasError,
      errorMessage,
      canisterId,
      cycles,
    } = await parseDepositDetails(depositDetails);

    if (hasError) {
      throw Error(errorMessage);
    }

    await global.ic?.plug?.requestBurnXTC({
      to: canisterId,
      amount: cycles,
    });
  } catch (error) {
    response = { hasError: true, errorMessage: error.message };
  }

  return response;
};

export const isValidCycles = (cycles) => ['bigint', 'number'].includes(typeof cycles) && cycles >= 0;

// TODO: decide on a library to properly format values across the project
// this relates to the format.js balance formater etc
// see custom fns with http://numeraljs.com/
export const convertCyclesToXTC = (cycles) => {
  if (typeof cycles === 'number' && !Number.isInteger(cycles)) {
    throw Error('Oops! Cycles value should be an integer.');
  }

  if (!isValidCycles(cycles)) {
    throw Error('Oops! Cycles value is not valid.');
  }

  if (cycles < 0) {
    throw Error('Oops! Cycles value is less then zero.');
  }

  return Number(parseFloat(cycles) / CYCLES_PER_TC);
};

// TODO: Use external API or Service to get IMG SDR Valuation
// by default this is hardtyped at latest known value 1.41 ~ 1.43
// https://www.imf.org/external/np/fin/data/rms_sdrv.aspx
const IMF_SDR_RATE = 1.42;
export const getXTCMarketValue = (xtc) => xtc * IMF_SDR_RATE;
