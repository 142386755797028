import { FETCH_CANISTERS_ACTIONS, UPDATE_CANISTER_BY_ID } from '@reducers/canisters';
import { Principal } from '@dfinity/principal';
import { parseExistingCanister, parseCanisterDetails } from '@utils/canister';
import { getDangerToastDispatchObj } from '@utils/toast-notifications';
import { convertCyclesToXTC } from '@utils/xtc';
import { formatBalance } from '@utils/formats';

export default {};

export const fetchAllCanisters = ({ t, projectActor }) => async (dispatch) => {
  // TODO: Provide the end user with some feedback, which would be a better UX
  if (!projectActor) return;

  dispatch({
    type: FETCH_CANISTERS_ACTIONS.LOADING,
  });

  try {
    const result = await projectActor.list_canisters();

    // TODO: the response comes as an array of array for some reason (investigate)
    const canisters = result?.data[0].map((canister) => ({
      id: canister.canister_id.toText(),
      name: canister.name,
      balance: canister.balance,
      type: canister.canister_type,
    }));

    dispatch({
      type: FETCH_CANISTERS_ACTIONS.SUCCESS,
      canisters,
      total: result.total,
    });
  } catch (err) {
    // TODO: handle BE list_canisters() errors
    dispatch({
      type: FETCH_CANISTERS_ACTIONS.ERROR,
    });
    dispatch(getDangerToastDispatchObj(t('canisters.table.errors.fetch')));

    // eslint-disable-next-line no-console
    console.warn(err);
  }
};

export const addExistingCanister = ({
  canister,
  projectActor,
  onSuccess,
  onFailure,
}) => async (dispatch) => {
  if (!projectActor) return;

  try {
    const {
      name,
      // TODO: update BE services to accept camel case naming conventions
      /* eslint-disable camelcase */
      principal: canister_id,
      hasError,
      errorMessage,
    } = await parseExistingCanister(canister);

    if (hasError) {
      throw Error(errorMessage);
    }

    const response = await projectActor.add_existing_canister({
      name,
      canister_id,
    });

    if (response.is_error) {
      throw Error(response.message);
    }

    onSuccess();
  } catch ({ message: description }) {
    onFailure();
    dispatch(getDangerToastDispatchObj(description));
  }
};

export const updateCanisterType = ({
  canister,
  projectActor,
  onSuccess,
  onFailure,
}) => async (dispatch) => {
  if (!projectActor) return;

  try {
    const principal = Principal.fromText(canister.id);

    const { is_error: isError, message } = await projectActor.update_canister_type(
      principal,
      canister.type,
    );

    if (isError) {
      throw Error(message);
    }

    onSuccess();
  } catch ({ message: description }) {
    onFailure();
    dispatch(getDangerToastDispatchObj(description));
  }
};

export const updateCanisterName = ({
  canister,
  projectActor,
  onSuccess,
  onFailure,
}) => async () => {
  if (!projectActor) return;

  try {
    const principal = Principal.fromText(canister.id);

    const response = await projectActor.update_canister_name(principal, canister.name);

    if (response.is_error) {
      // TODO: handle it, explain to the end user what happened
      onFailure();

      return;
    }

    onSuccess();
  } catch (e) {
    // TODO: Handle errors here while updating canister name
    /* eslint-disable no-console */
    console.error(e);
  }
};

export const deleteCanisterById = ({
  canisterId,
  projectActor,
  onSuccess,
  onFailure,
}) => async (dispatch) => {
  if (!projectActor) return;

  try {
    const principal = Principal.fromText(canisterId);

    const { is_error: isError, message } = await projectActor.delete_canister(
      principal,
    );

    if (isError) {
      throw Error(message);
    }

    onSuccess();
  } catch ({ message: description }) {
    onFailure();
    dispatch(getDangerToastDispatchObj(description));
  }
};

export const addNewCanister = ({
  canister,
  onSuccess,
  onFailure,
  projectActor,
}) => async (dispatch) => {
  try {
    const {
      hasError,
      errorMessage,
      name: canisterName,
      cycles,
      wasmFile,
    } = await parseCanisterDetails(canister);

    if (hasError) {
      throw Error(errorMessage);
    }

    const { is_error: isError, message } = await projectActor.create_canister({
      controller: [],
      cycles: BigInt(cycles),
      wasm_module: wasmFile,
      canister_name: canisterName,
    });

    if (isError) {
      // TODO: What to do when Err? Handle gracefully
      console.warn(message);

      throw Error(`Oops! Failed to create the canister ${canisterName}`);
    }

    onSuccess();
  } catch ({ message: description }) {
    onFailure();
    dispatch(getDangerToastDispatchObj(description));
  }
};

export const getCanisterBalanceById = ({
  canisterId,
  projectActor,
}) => async (dispatch) => {
  if (!projectActor) return;

  try {
    // update canister balance loading status in store
    dispatch({
      type: UPDATE_CANISTER_BY_ID,
      canisterId,
      canister: {
        loadingBalance: true,
      },
    });
    const principal = Principal.fromText(canisterId);
    let xtc = -1;

    // eslint-disable-next-line camelcase
    const {
      is_error: isError,
      message,
      data: canisterStatusData,
    } = await projectActor.canister_status({
      canister_id: principal,
    });

    // TODO: move canister_status response validation to util func

    if (isError) {
      // TODO: Handle gracefully
      // Identity type of error
      // For example, show the user what to do regarding Controller requirements
      // any other error do something about it...

      throw Error(message);
    }

    if (!canisterStatusData
        || !Array.isArray(canisterStatusData)
        || !canisterStatusData.length) {
      // TODO: Handle gracefully
      // What to do when a canister status response data
      // is not of a expected value format?

      throw Error('Oops! Unexpected canister status response data');
    }

    const { cycles } = canisterStatusData[0];

    if (!cycles) {
      // TODO: Handle gracefully
      // What to do when cycles is not available?

      throw Error('Oops! Missing canister status response data property');
    }

    xtc = convertCyclesToXTC(cycles);

    if (xtc < 0) {
      throw Error('Oops! balance is not valid.');
    }

    const canisterBalance = formatBalance(xtc, 2);

    // update canister XTC balance in store
    dispatch({
      type: UPDATE_CANISTER_BY_ID,
      canisterId,
      canister: {
        balance: canisterBalance,
        loadingBalance: false,
      },
    });
  } catch ({ message: description }) {
    // eslint-disable-next-line no-console
    console.warn(`Failed to retrieve canister balance (${description})`);

    // update canister balance loading status in store
    dispatch({
      type: UPDATE_CANISTER_BY_ID,
      canisterId,
      canister: {
        loadingBalance: false,
      },
    });
  }
};
