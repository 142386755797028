import React from 'react';
import clsx from 'clsx';
import { Button } from 'ui';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

const Pagination = (props) => {
  const {
    to,
    total,
    onNext,
    totalCanisters,
    className,
    onPrevious,
    from: fromItem,
    ...restProps
  } = props;

  return (
    <div
      className={clsx(
        'flex justify-between items-center',
        className,
      )}
      {...restProps}
    >
      <div className="text-sm text-gray-700 font-medium">
        <Trans
          i18nKey="common.pagination"
          values={{ from: fromItem, to, total }}
          components={{ bold: <span className="font-semibold" /> }}
        />
      </div>
      <div className="flex">
        <Button
          className="mr-3"
          onClick={onPrevious}
          disabled={fromItem <= 1}
        >
          <Trans i18nKey="common.previous" />
        </Button>
        <Button
          onClick={onNext}
          disabled={totalCanisters <= to}
        >
          <Trans i18nKey="common.next" />
        </Button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  className: null,
};

Pagination.propTypes = {
  className: PropTypes.string,
  to: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  totalCanisters: PropTypes.number.isRequired,
};

export default Pagination;
