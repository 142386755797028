import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Title = (props) => {
  const {
    children,
    className,
    ...restProps
  } = props;

  return (
    <span
      className={clsx(
        'text-sm text-gray-500',
        className,
      )}
      {...restProps}
    >
      {children}
    </span>
  );
};

Title.defaultProps = {
  children: null,
  className: null,
};

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Title;
