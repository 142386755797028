import React, { useState } from 'react';
import currency from 'currency.js';
import PropTypes from 'prop-types';
import {
  InputBase, Button, Icon, IconNames,
} from 'ui';
import MaskedInput from 'react-text-mask';
import { useTranslation, Trans } from 'react-i18next';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { defaultMaskOptions } from '../contants';

const AddStation = (props) => {
  const {
    balance,
    onCancel,
    onSubmit,
    threshold: initialThreshold,
    refillAmount: initialRefillAmount,
  } = props;

  const { t } = useTranslation();
  const currencyMask = createNumberMask(defaultMaskOptions);

  const [name, setName] = useState('');
  const [initialCycles, setInitialCycles] = useState('');
  const [threshold, setThreshold] = useState(initialThreshold);
  const [refillAmount, setRefillAmount] = useState(initialRefillAmount);

  const handleSubmit = () => {
    const data = {
      name,
      refillThreshold: currency(threshold).value,
      refillAmount: currency(refillAmount).value,
      initialCycles: currency(initialCycles).value,
    };

    onSubmit(data);
  };

  return (
    <>
      <div className="text-lg text-gray-900 font-medium mt-6">
        {t('modals.addChargingStation.steps.chargingStation.title')}
      </div>
      <div className="text-sm text-gray-500 mt-2">
        {t('modals.addChargingStation.steps.chargingStation.subtitle')}
      </div>
      <div className="mt-5">
        <label htmlFor="ch-name" className="block text-sm font-medium text-gray-700">
          {t('modals.addChargingStation.steps.chargingStation.form.chName.label')}
        </label>
        <div className="mt-1 relative">
          <InputBase
            name="ch-name"
            id="ch-name"
            placeholder={t('modals.addChargingStation.steps.chargingStation.form.chName.placeholder')}
            className="font-normal"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
          {t('modals.addChargingStation.steps.chargingStation.form.initialCycles.label')}
        </label>
        <div className="mt-1 relative">
          <InputBase
            name="cycles"
            id="cycles"
            data-testid="cycles-field-one"
            placeholder={t('modals.addChargingStation.steps.chargingStation.form.initialCycles.placeholder')}
            innerLabel="100.00 TC"
            className="font-normal"
            type="text"
            mask={currencyMask}
            component={MaskedInput}
            value={initialCycles}
            onChange={(e) => setInitialCycles(e.target.value)}
          />
        </div>
        <div className="text-sm text-gray-500">
          <Trans
            values={{
              valUSD: balance.usd || 0,
              valTC: balance.tc || 0,
            }}
            components={{ span: <span className="text-red-500" /> }}
          >
            {t('modals.addChargingStation.steps.chargingStation.form.initialCycles.message')}
          </Trans>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.addChargingStation.steps.chargingStation.form.refillThreshold.label')}
            <Icon
              icon={IconNames.infoCircle}
              className="text-gray-400"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              data-testid="cycles-field-two"
              type="text"
              name="cycles"
              value={threshold}
              placeholder="0.0"
              mask={currencyMask}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setThreshold(e.target.value)}
              innerLabel={`${currency(threshold).format({ symbol: '' })} IC`}
            />
          </div>
        </div>

        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.addChargingStation.steps.chargingStation.form.refillAmount.label')}
            <Icon
              icon={IconNames.infoCircle}
              className="ml-2"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              data-testid="cycles-field-three"
              type="text"
              name="cycles"
              placeholder="0.0"
              mask={currencyMask}
              value={refillAmount}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setRefillAmount(e.target.value)}
              innerLabel={`${currency(refillAmount).format({ symbol: '' })} IC`}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button className="w-full sm:ml-3 sm:w-auto" type="button" data-testid="submit-button" variant="primary" onClick={handleSubmit}>
          {t('common.continue')}
        </Button>
        <Button className="mt-3 w-full sm:mt-0 sm:w-auto" type="button" data-testid="cancel-button" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

AddStation.defaultProps = {
  balance: {},
  threshold: '',
  refillAmount: '',
};

AddStation.propTypes = {
  threshold: PropTypes.string,
  refillAmount: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  balance: PropTypes.shape({
    tc: PropTypes.number.isRequired,
    usd: PropTypes.number.isRequired,
  }),
};

export default AddStation;
