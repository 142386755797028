import React, { useState } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import copy from 'copy-to-clipboard';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import {
  Button,
  InputBase,
  CodeWindow,
  Icon,
  IconNames,
} from 'ui';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '$',
  suffix: ' USD',
  integerLimit: 7,
  decimalLimit: 2,
  allowDecimal: true,
  decimalSymbol: '.',
  allowNegative: false,
  allowLeadingZeroes: false,
  thousandsSeparatorSymbol: ',',
  includeThousandsSeparator: true,
};

/* eslint-disable quotes */
const CODE = `dfx canister --network ic call "zwigo-aiaaa-aaaaa-qaa3a-cai" authorize '(principal "chetu-3igui-2ub25-oblyz-in4om--eybjo-inqcg-w5rer-eqe")' uthorize '(principal "chetu-3igui-2ub25-uthorize '(principal).`;

const EditRefillSettings = (props) => {
  const {
    closeModal,
    threshold: initialThreshold,
    refillAmount: initialRefillAmount,
  } = props;
  const { t } = useTranslation();

  const [threshold, setThreshold] = useState(initialThreshold);
  const [refillAmount, setRefillAmount] = useState(initialRefillAmount);
  const currencyMask = createNumberMask(defaultMaskOptions);

  const onCopy = () => copy(CODE);

  const onSubmit = () => {
    /* eslint-disable no-console */
    console.log({
      threshold: currency(threshold).value,
      refillAmount: currency(refillAmount).value,
    });

    closeModal();
  };

  return (
    <div>
      <div className="text-lg text-gray-900 font-medium">
        {t('modals.editRefillSettings.title')}
      </div>
      <div className="text-sm text-gray-500 mt-2">
        {t('modals.editRefillSettings.subtitle')}
      </div>

      <CodeWindow
        onCopy={onCopy}
        className="mt-5"
        copyText={t('common.copy')}
      >
        $ {CODE}
      </CodeWindow>

      <div className="bg-blue-50 flex rounded-md p-4 mt-6">
        <Icon
          icon={IconNames.infoCircle}
          className="text-blue-400 mr-4"
          size="14px"
        />
        <div className="text-sm text-blue-800">
          {t('modals.editRefillSettings.info')}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.editRefillSettings.refillThreshold')}
            <Icon
              icon={IconNames.infoCircle}
              className="ml-2"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              data-testid="cycles-field-one"
              type="text"
              name="cycles"
              value={threshold}
              placeholder="0.0"
              mask={currencyMask}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setThreshold(e.target.value)}
              innerLabel={`${currency(threshold).format({ symbol: '' })} IC`}
            />
          </div>
        </div>

        <div className="mt-5">
          <label htmlFor="cycles" className="block text-sm font-medium text-gray-700">
            {t('modals.editRefillSettings.refillAmount')}
            <Icon
              icon={IconNames.infoCircle}
              className="ml-2"
              size="14px"
            />
          </label>
          <div className="mt-1 relative">
            <InputBase
              id="cycles"
              data-testid="cycles-field-two"
              type="text"
              name="cycles"
              placeholder="0.0"
              mask={currencyMask}
              value={refillAmount}
              className="font-normal"
              component={MaskedInput}
              onChange={(e) => setRefillAmount(e.target.value)}
              innerLabel={`${currency(refillAmount).format({ symbol: '' })} IC`}
            />
          </div>
        </div>
      </div>

      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button className="w-full sm:ml-3 sm:w-auto" type="button" variant="primary" onClick={onSubmit}>
          {t('modals.editRefillSettings.submit')}
        </Button>
        <Button className="mt-3 w-full sm:mt-0 sm:w-auto" type="button" data-testid="close-modal" onClick={() => closeModal()}>
          {t('common.cancel')}
        </Button>
      </div>
    </div>
  );
};

EditRefillSettings.defaultProps = {
  threshold: '',
  refillAmount: '',
};

EditRefillSettings.propTypes = {
  threshold: PropTypes.string,
  refillAmount: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

export default EditRefillSettings;
