export const PAGINATION_MAX = 10;

// Available plug connection status codes
export const plugStatusCodes = {
  verifying: 'VERIFYING',
  failedToConnect: 'FAILED_TO_CONNECT',
  connected: 'CONNECTED',
};

export const CANISTER_ID_MAX_LENGTH = 27;
export const CANISTER_NAME_MAX_LENGTH = 120;

export const CYCLES_PER_TC = 1_000_000_000_000;
export const XTC_MAX_VALUE = 1_000_000_000;

export const SUBNETS = [
  {
    id: 'mainnet',
    name: 'Mainnet',
  },
];
