import { USER_ACTIONS } from '@reducers/user';
import { getXTCAmount } from '@utils/xtc';

export default {};

export const updateUserBalance = () => async (dispatch) => {
  // set loading balance state to true
  dispatch({
    type: USER_ACTIONS.UPDATE,
    connected: true,
    loading: true,
  });

  const balanceInXTC = await getXTCAmount();

  // update user balance
  dispatch({
    type: USER_ACTIONS.UPDATE,
    user: {
      balance: balanceInXTC,
    },
    connected: true,
  });
};
