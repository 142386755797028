import { CYCLES_PER_TC, XTC_MAX_VALUE } from '@constants';

export default {};

export const formatToCycles = async (cyclesToken) => {
  let response = {
    hasError: false,
    errorMessage: '',
    cycles: 0,
  };

  try {
    if (!cyclesToken) {
      throw Error('Oops! XTC value should not be empty');
    }

    if (!parseFloat(cyclesToken) || typeof parseFloat(cyclesToken) !== 'number') {
      throw Error('Oops! XTC value is not valid.');
    }

    if (cyclesToken >= XTC_MAX_VALUE) {
      throw Error(`Oops! XTC value should be less than ${XTC_MAX_VALUE}`);
    }

    const cycles = parseInt(parseFloat(cyclesToken) * CYCLES_PER_TC, 10);
    response = { ...response, cycles };
  } catch (error) {
    response = { hasError: true, errorMessage: error.message };
  }

  return response;
};

// TODO: decide on a library to properly format values across the project
// this relates to the xtc convertCyclesToXTC etc
// see custom fns with http://numeraljs.com/
export const formatBalance = (balance, dp = 4) => +parseFloat(balance).toFixed(dp);
