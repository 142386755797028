export const USER_ACTIONS = {
  UPDATE: 'USER_UPDATE',
  LOADING: 'USER_LOADING',
  ERROR: 'USER_ERROR',
};

const defaultState = {
  user: {
    principalId: null,
    balance: null,
  },
  loading: false,
  connected: false,
  error: false,
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_ACTIONS.UPDATE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
        connected: action.connected,
        loading: action.loading,
      };
    }
    case USER_ACTIONS.LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case USER_ACTIONS.ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
