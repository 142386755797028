import React from 'react';

const WarningYellow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="20" fill="#FBBF24" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7013 10.3915C18.7096 8.53618 21.2904 8.53617 22.2987 10.3915L29.6575 23.9319C30.6464 25.7516 29.3759 28 27.3588 28H12.6412C10.6241 28 9.35361 25.7516 10.3425 23.9319L17.7013 10.3915ZM21.3186 23.9055C21.3186 24.6593 20.7282 25.2704 19.9999 25.2704C19.2716 25.2704 18.6812 24.6593 18.6812 23.9055C18.6812 23.1517 19.2716 22.5406 19.9999 22.5406C20.7282 22.5406 21.3186 23.1517 21.3186 23.9055ZM19.9999 12.9864C19.2716 12.9864 18.6812 13.5975 18.6812 14.3513V18.4459C18.6812 19.1997 19.2716 19.8108 19.9999 19.8108C20.7282 19.8108 21.3186 19.1997 21.3186 18.4459V14.3513C21.3186 13.5975 20.7282 12.9864 19.9999 12.9864Z"
      fill="white"
    />
  </svg>
);

export default WarningYellow;
