import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { idlFactory } from '@dfx/canisterium';
import { plugStatusCodes } from '@constants';
import { isPlugInstalled, isConnectedToPlug } from '../../utils/plug';
import { isDfxNetworkLocal } from '../../utils/dfx';
import config from '../../config';

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [plugConnectionStatus, setPlugConnectionStatus] = useState(plugStatusCodes.verifying);
  const [principalId, setPrincipalId] = useState('');
  const [projectActor, setProjectActor] = useState(false);
  const hasPlug = isPlugInstalled();
  const isLocalDfx = isDfxNetworkLocal();

  const handleConnectToPlug = async () => {
    const { host } = config;
    const whitelist = [config.canisterId];
    await window.ic.plug.createAgent({ whitelist, host });
    setPlugConnectionStatus(plugStatusCodes.connected);
  };

  const verifyPlugConnection = async () => {
    if (!hasPlug) {
      setPlugConnectionStatus(plugStatusCodes.failedToConnect);

      return;
    }

    const connected = await window.ic.plug.isConnected();

    if (!connected) {
      setPlugConnectionStatus(plugStatusCodes.failedToConnect);

      return;
    }

    if (!window.ic.plug?.agent) {
      setProjectActor(false);
      handleConnectToPlug();
      return;
    }

    setPlugConnectionStatus(plugStatusCodes.connected);
  };

  useEffect(async () => {
    verifyPlugConnection();
  }, []);

  useEffect(async () => {
    // Doesn't satisfy, exit immediatelly...
    if (!isConnectedToPlug(plugConnectionStatus)) return;

    // Get principal Id
    const principal = await window.ic.plug.agent.getPrincipal();

    // TODO: Handle if no principal id returned from Plug agent
    if (!principal) return;

    // Storing principal Id to utilise it across the app
    setPrincipalId(principal.toText());

    // Verify agent certification when dfx is local
    if (isLocalDfx) await window.ic.plug.agent.fetchRootKey();

    // create an actor with the help of Plug CreateActor call
    // we pass the canister id and the interface factory
    const actor = await window.ic.plug.createActor({
      canisterId: config.canisterId,
      interfaceFactory: idlFactory,
    });

    if (!actor) {
      // TODO: Handle if actor creation failed

      return;
    }

    setProjectActor(actor);
  }, [plugConnectionStatus]);

  return (
    <AuthContext.Provider
      value={{
        plugConnectionStatus,
        principalId,
        handleConnectToPlug,
        // We can use any method described in the Candid (IDL)
        // for example `projectActor.list_canisters()`
        projectActor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
