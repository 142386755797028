import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Select,
  MenuItem,
  FileInput,
  RadioGroup,
} from 'ui';

import getOptions from './get-options';

const UpgradeWASM = (props) => {
  const {
    canisters,
    closeModal,
    selectedCanisterId,
  } = props;

  const { t } = useTranslation();
  const initialOptions = getOptions(t);

  const [file, setFile] = useState(null);
  const [options, setOptions] = useState(initialOptions);
  const [selectedCanister, setSelectedCanister] = useState(selectedCanisterId);

  const onChangeCanister = (canister) => setSelectedCanister(canister);

  const onClickOption = (e) => setOptions((currentOptions) => {
    const newOptions = currentOptions.map((opt) => {
      if (opt.id === e.target.value) {
        return { ...opt, selected: true };
      }

      return { ...opt, selected: false };
    });

    return newOptions;
  });

  const onConfirm = () => {
    const form = {
      file,
      method: options.find((opt) => opt.selected).id,
      canister: canisters.find((canister) => canister.id === selectedCanister),
    };

    // TODO: integrate method
    /* eslint-disable no-console */
    console.log(form);
    closeModal();
  };

  return (
    <div>
      <div className="text-lg text-gray-900 font-medium">
        {t('modals.upgradeWASM.title')}
      </div>
      <div className="text-sm text-gray-500 mt-2">
        {t('modals.upgradeWASM.subtitle')}
      </div>
      <div className="mt-4">
        <label htmlFor="canister" className="block text-sm font-medium text-gray-700">
          {t('modals.upgradeWASM.canisterToUpgrade')}
        </label>
        <div className="mt-1 relative">
          <Select
            name="canister"
            id="canister"
            value={selectedCanister}
            onChange={onChangeCanister}
            classes={{
              list: 'z-10 overflow-y-scroll sm:max-h-44 max-h-52 ',
            }}
          >
            {canisters.map((canister) => (
              <MenuItem key={canister.id} value={canister.id}>
                <span>{canister.name}</span>
                <span className="text-gray-500 group-hover:text-white">
                  &nbsp;{`(${canister.id})`}
                </span>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt-5">
        <div className="block text-sm font-medium text-gray-700 mb-1">
          {t('modals.addCanister.wasmFile')}
        </div>
        <FileInput
          onChange={(wasmFile) => setFile(wasmFile)}
          dropzoneConfig={{
            maxFiles: 1,
            accept: 'application/wasm',
          }}
          i18n={{
            update: t('common.update'),
            remove: t('common.remove'),
            mainTextl1: t('modals.upgradeWASM.uploadFilel1'),
            mainTextl2: t('modals.upgradeWASM.uploadFilel2'),
          }}
        />
      </div>
      <div className="mt-5">
        <div className="block text-sm font-medium text-gray-700 mb-2">
          {t('modals.upgradeWASM.pickOption')}
        </div>
        <RadioGroup
          options={options}
          name="wasm-upload-options"
          onClickOption={onClickOption}
        />
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button className="w-full sm:ml-3 sm:w-auto" type="button" variant="primary" onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
        <Button className="mt-3 w-full sm:mt-0 sm:w-auto" type="button" data-testid="close-modal" onClick={closeModal}>
          {t('common.cancel')}
        </Button>
      </div>
    </div>
  );
};

UpgradeWASM.defaultProps = {
  canisters: [],
  selectedCanisterId: null,
};

UpgradeWASM.propTypes = {
  selectedCanisterId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  canisters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default UpgradeWASM;
