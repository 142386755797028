import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from 'ui';
import { removeToastNotification } from '../../actions';

/**
 *
 * @param {Object} props
 * @param {string} props.className
 * @returns
 */
function ToastNotifications({
  className,
}) {
  const dispatch = useDispatch();
  /** @type {{ toasts: import('../../reducers/toast-notifications').Toast[] }} */
  const { toasts } = useSelector((s) => s.toastNotifications);

  return (
    <div className={clsx('absolute px-2 py-3 bottom-0 w-full space-y-6 z-10', className)}>
      {
        toasts.map(({
          id,
          show,
          status,
          icon,
          description,
        }) => (
          <Transition
            show={show}
            key={id}
            enter="transition-opacity duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-450"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Alert
              icon={icon}
              status={status}
              description={description}
              onDismiss={(event) => {
                event.preventDefault();

                dispatch(removeToastNotification(id));
              }}
            />
          </Transition>
        ))
      }
    </div>
  );
}

ToastNotifications.defaultProps = {
  className: '',
};

ToastNotifications.propTypes = {
  className: PropTypes.string,
};

export default ToastNotifications;
