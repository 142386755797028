import React from 'react';
import PropTypes from 'prop-types';

import RenderHeaders from '../RenderHeaders';
import GenericTable from '../GenericTable';
import RenderRow from './components/RenderRow';

const TransactionsTable = ({
  i18n,
  data,
  className,
  menuOptions,
  onActionClickOption,
}) => {
  const headers = [
    { label: i18n.transactionsTable.timestamp },
    { label: i18n.transactionsTable.action },
    { label: i18n.transactionsTable.canister },
    { label: i18n.transactionsTable.amount, align: 'right' },
  ];

  return (
    <GenericTable
      i18n={i18n}
      data={data}
      headers={headers}
      className={className}
      RenderRow={RenderRow}
      menuOptions={menuOptions}
      RenderHeader={RenderHeaders}
      onActionClickOption={onActionClickOption}
    />
  );
};

TransactionsTable.defaultProps = {
  className: '',
  onActionClickOption: () => {},
};

TransactionsTable.propTypes = {
  className: PropTypes.string,
  onActionClickOption: PropTypes.func,
  menuOptions: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  i18n: PropTypes.shape({
    transactionsTable: PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      canister: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default TransactionsTable;
