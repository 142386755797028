import { Buffer } from 'buffer';

export default {};

export const readFileAsync = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    resolve(Buffer.from(e.target.result));
  };

  reader.onerror = reject;

  reader.readAsArrayBuffer(file);
});
