import React from 'react';
import PropTypes from 'prop-types';

import {
  TextCell,
  TextActionCell,
} from 'ui';

import lightningBolt from './lightning-bolt.svg';
import depositMoney from './deposit-money.svg';

const RenderRow = ({
  data,
  menuOptions,
  onActionClickOption,
  i18n,
}) => {
  const {
    type,
    timestamp,
    canister,
    amount,
  } = data;

  const actionCell = () => {
    const title = type === 'deposit' ? i18n.transactionsTable.types.deposit : i18n.transactionsTable.types.charged;
    const image = type === 'deposit' ? depositMoney : lightningBolt;

    return (
      <td className="text-left py-4 px-6 text-sm">
        <div className="flex flex-row">
          <div className="mr-1">
            <img alt="action-icon" className="h-5" src={image} />
          </div>
          <span className="font-medium">
            {title}
          </span>
        </div>
      </td>
    );
  };

  return (
    <tr className="border-t border-gray-200 render-row">
      <TextCell textContent={timestamp} boldText />
      {actionCell()}
      {
        canister ? (
          <td className="px-6 py-4">
            <div className="flex flex-col md:flex-row">
              <span>{canister.name}</span>
              <span className="md:ml-1 text-gray-500">{`(${canister.id})`}</span>
            </div>
          </td>
        ) : (
          <TextCell
            textContent="N/A"
          />
        )
      }
      <TextActionCell
        textContent={`${amount} TC`}
        secondaryContent={`$${amount}`}
        align="end"
        boldText
        menuOptions={menuOptions(data)}
        onClickOption={(closeMenu, optionId) => onActionClickOption(optionId, data, closeMenu)}
      />
    </tr>
  );
};

RenderRow.defaultProps = {
  menuOptions: () => {},
};

RenderRow.propTypes = {
  onActionClickOption: PropTypes.func.isRequired,
  menuOptions: PropTypes.func,
  data: PropTypes.shape({
    type: PropTypes.oneOf(['charged', 'deposit']),
    id: PropTypes.string,
    amount: PropTypes.string,
    timestamp: PropTypes.string,
    canister: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ]),
  }).isRequired,
  i18n: PropTypes.shape({
    transactionCharged: PropTypes.string.isRequired,
    transactionDeposit: PropTypes.string.isRequired,
    transactionsTable: PropTypes.shape({
      types: PropTypes.shape({
        deposit: PropTypes.string.isRequired,
        charged: PropTypes.string.isRequired,
      }).isRequired,
      timestamp: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      canister: PropTypes.string.isRequired,
      amount: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default RenderRow;
