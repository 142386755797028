import React from 'react';
import { Button } from 'ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// TODO: replace with fleekon/icons version
import WarningYellow from '../../../Icon/WarningYellow';

const LowCycles = ({ closeModal }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-10 sm:w-10">
          <WarningYellow />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            {t('modals.lowCycles.title')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t('modals.lowCycles.description')}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button
          type="button"
          data-testid="close-modal-one"
          variant="primary"
          onClick={closeModal}
          className="w-full sm:ml-3 sm:w-auto"
        >
          {t('modals.lowCycles.submit')}
        </Button>
        <Button
          type="button"
          data-testid="close-modal-two"
          onClick={closeModal}
          className="mt-3 w-full sm:mt-0 sm:w-auto"
        >
          {t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

LowCycles.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default LowCycles;
