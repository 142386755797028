import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'ui';
import { useSidebar, usePagination } from '@hooks';
import { useTranslation } from 'react-i18next';
import {
  Pagination,
  NoCanisters,
  // TODO: CanistersTable is not generic, make it generic
  CanistersTable,
  AddCanisterBtn,
  ToastNotifications,
} from '@components';
import { USER_ACTIONS } from '@reducers/user';
import { getXTCAmount } from '@utils/xtc';

import { useAuth } from '../../contexts/AuthContext';
import { fetchAllCanisters } from '../../actions';

import useMenuOptions from './useMenuOptions';

// Calculate the interval timeout 5m
const INTERVAL_TIMEOUT = (60 * 1000) * 5;

function CanistersView() {
  const { projectActor } = useAuth();
  const { t } = useTranslation();
  const { sidebar } = useSidebar();
  const dispatch = useDispatch();
  const [allCanisters, fetchCanisters] = useSelector((state) => [
    state.canisters.canisters,
    state.canisters.fetchCanisters,
  ]);

  const canisters = allCanisters.filter((canister) => (
    fetchCanisters.ids.includes(canister.id)
  ));

  const getCanisters = async () => {
    dispatch(fetchAllCanisters({ t, projectActor }));
  };

  const {
    onNext,
    onPrevious,
    currentPaginationIndex,
    PAGINATION_MAX,
    getToValue,
    getShownData,
  } = usePagination({
    fetchMore: getCanisters,
    total: fetchCanisters.total,
    fetchedDataAmount: fetchCanisters.ids.length,
    data: canisters,
  });

  const [
    menuOptions,
    onActionClickOption,
    defaultAction,
  ] = useMenuOptions(canisters, 208.5);

  const getXTCHandler = async ({
    connected,
    principal,
  }) => {
    const balanceInXTC = await getXTCAmount();

    dispatch({
      type: USER_ACTIONS.UPDATE,
      user: {
        balance: balanceInXTC,
        principalId: principal.toText(),
        totalChargingStationsRefillAmount: '',
      },
      connected,
    });
  };

  useEffect(async () => {
    dispatch({
      type: USER_ACTIONS.UPDATE,
      user: {
        balance: 0,
        principalId: '',
        totalChargingStationsRefillAmount: '',
      },
      connected: false,
      loading: false,
    });
  }, []);

  useEffect(async () => {
    if (!projectActor) return;

    dispatch({
      type: USER_ACTIONS.UPDATE,
      user: {
        balance: 0,
        principalId: '',
        totalChargingStationsRefillAmount: '',
      },
      connected: true,
      loading: true,
    });

    const connected = await window.ic?.plug?.isConnected();
    const principal = await window.ic?.plug?.agent.getPrincipal();

    // Call once
    getXTCHandler({
      connected,
      principal,
    });

    // Recurring calls
    const pid = setInterval(() => {
      // Set loading state
      dispatch({
        type: USER_ACTIONS.UPDATE,
        loading: true,
      });

      getXTCHandler({
        connected,
        principal,
      });
    }, INTERVAL_TIMEOUT);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(pid);
  }, [projectActor]);

  useEffect(() => {
    const maxDisplayedRowIndex = currentPaginationIndex + PAGINATION_MAX - 1;
    if (maxDisplayedRowIndex > fetchCanisters.ids.length - 1) {
      getCanisters({
        first: PAGINATION_MAX,
        offset: currentPaginationIndex,
      });
    }
  }, [currentPaginationIndex, projectActor]);

  const noCanisters = canisters.length <= 0 && !fetchCanisters.loading;

  return (
    <Layout sidebar={sidebar} updatingText={t('wallet.updatingText')} connectingText={t('wallet.connectingText')}>
      <ToastNotifications className="hidden z-50 md:block" />
      <div className="flex flex-col relative z-0 h-full">
        <div className="flex px-6 mt-6 mb-5 items-center justify-between">
          <div className="flex">
            <img src={`${process.env.PUBLIC_URL}/assets/oil_drum.svg`} alt="oil_drum" />
            <span className="ml-2">{t('canisters.title')}</span>
          </div>
          <AddCanisterBtn data-testid="addCanisterButton" t={t} />
        </div>
        <div className={clsx(!noCanisters && 'table-container overflow-scroll w-screen md:w-auto flex-1')}>
          <CanistersTable
            loading={fetchCanisters.loading}
            maxRows={PAGINATION_MAX}
            data={getShownData()}
            menuOptions={menuOptions}
            defaultAction={defaultAction}
            onActionClickOption={onActionClickOption}
            i18n={{
              chargingStation: t('canisters.chargingStation'),
            }}
            headers={[
              { label: t('canisters.table.headers.name') },
              { label: t('canisters.table.headers.id') },
              { label: t('canisters.table.headers.balance'), align: 'right' },
              { label: t('canisters.table.headers.type'), align: 'right' },
              { label: '' },
            ]}
          />
        </div>
        {noCanisters ? (
          <NoCanisters t={t} />
        ) : (
          <Pagination
            to={getToValue()}
            from={currentPaginationIndex + 1}
            total={fetchCanisters.total || 1}
            totalCanisters={canisters.length}
            onNext={onNext}
            onPrevious={onPrevious}
            className="px-6 py-3 border-t border-gray-200"
          />
        )}
      </div>
    </Layout>
  );
}

export default CanistersView;
