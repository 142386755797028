// TODO: Verify if renders correctly
// TODO: Create story for this modal
import { useState } from 'react';
import { Step } from 'ui';
import PropTypes from 'prop-types';
import { styled } from '@stitches/react';

import { AddStation, AddCanisters, AddCode } from '../components';

const CharginStationSteps = styled('div', {
  '& > button': {
    maxWidth: '140px',
  },
});

const AddChargingStation = (props) => {
  const {
    balance,
    canisters,
    closeModal,
  } = props;

  const [step, setStep] = useState(0);
  const [form, setForm] = useState({
    name: '',
    canisters: [],
    refillAmount: 0,
    initialCycles: 0,
    refillThreshold: 0,
  });

  const onSubmit = (data) => {
    const newFormData = {
      ...form,
      refillAmount: data.refillAmount,
      refillThreshold: data.refillThreshold,
    };

    setForm(newFormData);

    /* TODO: Add integration here */
    // eslint-disable-next-line no-console
    console.log(newFormData);
    closeModal();
  };

  let content = null;

  switch (step) {
    case 0:
      content = (
        <AddStation
          threshold="0"
          refillAmount="0"
          balance={balance}
          onCancel={closeModal}
          onSubmit={(data) => {
            setForm((formData) => ({
              ...formData,
              name: data.name,
              refillAmount: data.refillAmount,
              initialCycles: data.initialCycles,
              refillThreshold: data.refillThreshold,
            }));
            setStep(1);
          }}
        />
      );
      break;

    case 1:
      content = (
        <AddCanisters
          canisters={canisters}
          onCancel={closeModal}
          onSubmit={(selectedCanisters) => {
            setForm((formData) => ({
              ...formData,
              canisters: selectedCanisters,
            }));
            setStep(2);
          }}
        />
      );
      break;

    case 2:
      content = (
        <AddCode
          threshold={form.refillThreshold.toString()}
          refillAmount={form.refillAmount.toString()}
          onCancel={closeModal}
          onSubmit={onSubmit}
        />
      );
      break;

    default:
      content = null;
      break;
  }

  return (
    <div>
      <CharginStationSteps className="flex justify-between charging-station-steps">
        <Step
          title="Charging Station"
          subTitle="Step 1"
          highligh={step === 0}
          component="button"
          onClick={() => setStep(0)}
          className="transform active:scale-95 active:opacity-50 transition-all duration-300 outline-none"
        />
        <Step
          title="Add Canisters"
          subTitle="Step 2"
          highligh={step === 1}
          component="button"
          onClick={() => setStep(1)}
          className="transform active:scale-95 active:opacity-50 transition-all duration-300 outline-none"
        />
        <Step
          title="Add Code"
          subTitle="Step 3"
          highligh={step === 2}
          component="button"
          onClick={() => setStep(2)}
          className="transform active:scale-95 active:opacity-50 transition-all duration-300 outline-none"
        />
      </CharginStationSteps>
      {content}
    </div>
  );
};

AddChargingStation.defaultProps = {
  balance: {},
  canisters: [],
};

AddChargingStation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  balance: PropTypes.shape({
    tc: PropTypes.number.isRequired,
    usd: PropTypes.number.isRequired,
  }),
  canisters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default AddChargingStation;
