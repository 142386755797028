import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AddExistingCanister } from 'ui';
import { useAuth } from '../../../../contexts/AuthContext';
import { addExistingCanister, fetchAllCanisters } from '../../../../actions';

const AddExistingCanisterModal = (props) => {
  const { closeModal } = props;
  const { projectActor } = useAuth();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onAddCanister = (canisterName, canisterId) => {
    setLoading(true);
    dispatch(addExistingCanister({
      projectActor,
      canister: {
        id: canisterId,
        name: canisterName,
      },
      onSuccess: () => {
        dispatch(fetchAllCanisters({ t, projectActor }));
        setLoading(false);
        closeModal();
      },
      onFailure: () => {
        setLoading(false);
      },
    }));
  };

  return (
    <AddExistingCanister
      closeModal={closeModal}
      handleAddCanister={onAddCanister}
      title={t('modals.addExistingCanister.title')}
      description={t('modals.addExistingCanister.subtitle')}
      canisterNameLabel={t('modals.addExistingCanister.canisterName')}
      canisterNamePlaceholder={t('modals.addExistingCanister.canisterName')}
      canisterIdLabel={t('modals.addExistingCanister.canisterId')}
      canisterIdPlaceholder={t('modals.addExistingCanister.canisterId')}
      addCanisterBtnTitle={t('modals.addCanister.title')}
      closeModalBtnTitle={t('common.cancel')}
      loading={loading}
    />
  );
};

AddExistingCanisterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default AddExistingCanisterModal;
