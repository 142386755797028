import { canisterId } from '@dfx/canisterium';
import dfxConfig from '../../../dfx.json';

const env = process.env.NODE_ENV || 'development';

if (!canisterId) throw Error(`Oops! Missing the ${env} environment canisterId.`);

const config = {
  production: {
    canisterId,
    dfxNetwork: process.env.DFX_NETWORK || 'ic',
    host: !process.env.DFX_NETWORK ? dfxConfig.networks.local.bind : undefined,
  },
  staging: {
    canisterId,
    dfxNetwork: process.env.DFX_NETWORK || 'ic',
    host: !process.env.DFX_NETWORK ? dfxConfig.networks.local.bind : undefined,
  },
  development: {
    canisterId,
    dfxNetwork: process.env.DFX_NETWORK || 'local',
    host: !process.env.DFX_NETWORK ? dfxConfig.networks.local.bind : undefined,
  },
};

export default config[env];

// eslint-disable-next-line no-console
console.log(`Config ENV=${env}:`, config[env]);
