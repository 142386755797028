import { Provider } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  HashRouter as Router,
} from 'react-router-dom';

import Cycle from './views/Cycle';
import Modal from './components/Modal';
import Canisters from './views/Canisters';
import CanisterDetail from './views/CanisterDetail';
import { ToastNotifications } from './components';

import AuthProvider from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';

import store from './store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute path="/canisters" exact>
              <Canisters />
            </PrivateRoute>
            <Route path="/cycle" exact>
              <Cycle />
            </Route>
            <Route path="/canisters/:id" exact>
              <CanisterDetail />
            </Route>
            <Redirect to="/canisters" />
          </Switch>
          <Modal />
        </AuthProvider>
      </Router>
      <ToastNotifications className="md:hidden" />
    </Provider>
  );
}

export default App;
