import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import formatAddress from '@utils/format-address';

import { DocsCard } from '../components';

export default function useSidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  const [user] = useSelector((state) => ([
    state.user,
  ]));
  const lowBalanceMessageThresholdPercentage = 1.2;
  const showLowBalanceMessage = () => {
    const balance = Number(user.user.balance);
    const totalChargingStationsRefillAmount = Number(user.user.totalChargingStationsRefillAmount);
    if (totalChargingStationsRefillAmount === 0) {
      return false;
    }
    const lowBalanceThreshold = totalChargingStationsRefillAmount
      * lowBalanceMessageThresholdPercentage;
    if (balance < lowBalanceThreshold) {
      return true;
    }
    return false;
  };

  return {
    sidebar: {
      bottomContent: (
        <DocsCard t={t} />
      ),
      navLinks: [
        {
          id: 'canisters',
          label: 'Canisters',
          path: '/canisters',
          active: location.pathname === '/canisters',
          imgSrc: `${process.env.PUBLIC_URL}/assets/oil_drum.svg`,
        },
      ],
      accountDetails: {
        cycles: user.user.balance,
        address: formatAddress(user.user.principalId),
        loading: user.loading,
        connected: user.connected,
      },
      lowBalanceAlert: {
        show: showLowBalanceMessage(),
        message: t('sidebar.lowBalance'),
      },
    },
  };
}
