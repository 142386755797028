export const defaultMaskOptions = {
  prefix: '$',
  suffix: ' USD',
  integerLimit: 7,
  decimalLimit: 2,
  allowDecimal: true,
  decimalSymbol: '.',
  allowNegative: false,
  allowLeadingZeroes: false,
  thousandsSeparatorSymbol: ',',
  includeThousandsSeparator: true,
};

// TODO: replace this url by the real documentation url
export const docsURL = 'https://www.google.com';
