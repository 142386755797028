export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

const defaultState = {
  open: false,
  modal: null,
  props: {},
};

const modalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        open: true,
        ...action.payload,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
