import { TOAST_NOTIFICATIONS_ACTIONS } from '../reducers/toast-notifications';

/**
 * @param {import('../reducers/toast-notifications').Toast} toast
*/
export const addToastNotification = (toast) => (dispatch) => {
  dispatch({
    type: TOAST_NOTIFICATIONS_ACTIONS.ADD_TOAST,
    toast: {
      ...toast,
      show: false,
    },
  });

  // wait to component to be mounted and trigger the transition
  setTimeout(() => {
    dispatch({
      toastId: toast.id,
      type: TOAST_NOTIFICATIONS_ACTIONS.HIDE_OR_SHOW_TOAST,
    });
  });
};

/**
 * @param {string} toastId
 */
export const removeToastNotification = (toastId) => (dispatch) => {
  dispatch({
    toastId,
    type: TOAST_NOTIFICATIONS_ACTIONS.HIDE_OR_SHOW_TOAST,
  });

  // remove toast notification after transition is done
  setTimeout(() => {
    dispatch({
      toastId,
      type: TOAST_NOTIFICATIONS_ACTIONS.REMOVE_TOAST,
    });
  }, 450);
};
