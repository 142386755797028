import React from 'react';
import PropTypes from 'prop-types';

import AddCanisterBtn from '../AddCanisterBtn';

function NoCanisters({ t }) {
  return (
    <div className="relative mt-12 flex flex-col items-center justify-center md:mt-40">
      <span className="text-6xl mb-3">🤔</span>
      <h3 className="text-2xl font-semibold mb-5">{t('canisters.no_canisters')}</h3>
      <AddCanisterBtn data-testid="addCanisterButton" t={t} />
    </div>
  );
}

NoCanisters.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NoCanisters;
