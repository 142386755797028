import React from 'react';
import PropTypes from 'prop-types';

import {
  AddressCell,
  NameCell,
  TextCell,
  TextActionCell,
} from 'ui';

const RenderRow = ({
  data,
  menuOptions,
  onActionClickOption,
}) => {
  const {
    id,
    name,
    status,
    cyclesUsed,
    refillAt,
    amount,
    lastRefill,
    chargingStationId,
    refillCount,
  } = data;

  return (
    <tr className="border-t border-gray-200 render-row">
      <NameCell name={name} status={status} />
      <AddressCell
        address={id}
        chargingStation={chargingStationId}
      />
      <TextCell textContent={`${cyclesUsed} TC`} secondaryContent={`$${cyclesUsed}`} boldText />
      <TextCell textContent={refillCount} boldText />
      <TextCell textContent={lastRefill} boldText />
      <TextActionCell
        textContent={`$${refillAt} / $${amount}`}
        align="end"
        boldText
        menuOptions={menuOptions(data)}
        onClickOption={(closeMenu, optionId) => onActionClickOption(optionId, data, closeMenu)}
      />
    </tr>
  );
};

RenderRow.defaultProps = {
  menuOptions: () => {},
};

RenderRow.propTypes = {
  onActionClickOption: PropTypes.func.isRequired,
  menuOptions: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    chargingStationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    cyclesUsed: PropTypes.string,
    balance: PropTypes.string,
    refillAt: PropTypes.string,
    amount: PropTypes.string,
    lastRefill: PropTypes.string,
    refillCount: PropTypes.string,
  }).isRequired,
};

export default RenderRow;
