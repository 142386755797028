import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { openModal, closeModal, modalsKeys } from '@components/Modal';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../contexts/AuthContext';
import { isConnectionToPlugFailed } from '../../utils/plug';

const Container = ({
  component,
}) => {
  const { plugConnectionStatus } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!plugConnectionStatus) return;

    dispatch(
      isConnectionToPlugFailed(plugConnectionStatus)
        ? openModal(modalsKeys.connectToPlug)
        : closeModal(),
    );
  }, [plugConnectionStatus]);

  return component;
};

const PrivateRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={() => <Container component={children} />}
  />
);

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
