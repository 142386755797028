import { burnXTCCycles } from '@utils/xtc';
import { getDangerToastDispatchObj } from '@utils/toast-notifications';

export default {};

export const transferXTCcycles = ({
  depositDetails,
  onSuccess,
  onFailure,
}) => async (dispatch) => {
  try {
    const { hasError, errorMessage } = await burnXTCCycles(depositDetails);

    if (hasError) {
      throw Error(errorMessage);
    }

    onSuccess();
  } catch ({ message: description }) {
    onFailure();
    dispatch(getDangerToastDispatchObj(description));
  }
};
