import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LoadingRow from '../LoadingRow';

const GenericTable = ({
  i18n,
  data,
  headers,
  className,
  RenderRow,
  menuOptions,
  RenderHeader,
  onActionClickOption,
  loading,
  maxRows,
}) => {
  const rows = data.map((rowData) => (
    <RenderRow
      i18n={i18n}
      data={rowData}
      key={rowData.id}
      menuOptions={menuOptions}
      onActionClickOption={onActionClickOption}
    />
  ));
  const loadingRowsAmount = maxRows > data.lenght ? data.lenght : maxRows;
  const LoadingRows = Array(loadingRowsAmount).fill(null).map(() => (
    <LoadingRow />
  ));

  return (
    <table className={clsx('w-full relative', className)}>
      <thead>
        <RenderHeader headers={headers} />
      </thead>
      <tbody>
        {rows}
        {loading && (
          LoadingRows
        )}
      </tbody>
    </table>
  );
};

GenericTable.defaultProps = {
  className: '',
  onActionClickOption: () => {},
  loading: false,
  maxRows: 10,
};

GenericTable.propTypes = {
  className: PropTypes.string,
  onActionClickOption: PropTypes.func,
  menuOptions: PropTypes.func.isRequired,
  RenderRow: PropTypes.elementType.isRequired,
  RenderHeader: PropTypes.elementType.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  i18n: PropTypes.shape({
    chargingStation: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  maxRows: PropTypes.number,
};

export default GenericTable;
