import en from './en/translation.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resources: {
    en: { translation: en },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
};
