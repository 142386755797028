import TestModal from './TestModal';
import LowCycles from './LowCycles';
import AddCycles from './AddCycles';
import UpgradeWASM from './UpgradeWASM';
import AddCanister from './AddCanister';
import StopCanister from './StopCanister';
import DepositCycles from './DepositCycles';
import DeleteCanister from './DeleteCanister';
import AddCanistersToCS from './AddCanistersToCS';
import ChangeCanisterName from './ChangeCanisterName';
import EditRefillSettings from './EditRefillSettings';
import AddChargingStation from './AddChargingStation';
import AddExistingCanister from './AddExistingCanister';
import ConnectToPlug from './ConnectToPlug';
import ChangeCanisterType from './ChangeCanisterType';

export const modalsKeys = {
  test: 'TEST',
  addCycles: 'ADD_CYCLES',
  lowCycles: 'LOW_CYCLES',
  addCanister: 'ADD_CANISTER',
  upgradeWASM: 'UPGRADE_WASM',
  stopCanister: 'STOP_CANISTER',
  depositCycles: 'DEPOSIT_CYCLES',
  deleteCanister: 'DELETE_CANISTER',
  changeCanisterName: 'CHANGE_CANISTER_NAME',
  editRefillSettings: 'EDIT_REFILL_SETTINGS',
  addChargingStation: 'ADD_CHARGING_STATION',
  addExistingCanister: 'ADD_EXISTING_CANISTER',
  addCanistersToChargingStation: 'ADD_CANISTERS_TO_CHARGING_STATION',
  connectToPlug: 'CONNECT_TO_PLUG',
  changeCanisterType: 'CHANGE_CANISTER_TYPE',
};

const modals = {
  [modalsKeys.test]: TestModal,
  [modalsKeys.addCycles]: AddCycles,
  [modalsKeys.lowCycles]: LowCycles,
  [modalsKeys.upgradeWASM]: UpgradeWASM,
  [modalsKeys.addCanister]: AddCanister,
  [modalsKeys.stopCanister]: StopCanister,
  [modalsKeys.depositCycles]: DepositCycles,
  [modalsKeys.deleteCanister]: DeleteCanister,
  [modalsKeys.changeCanisterName]: ChangeCanisterName,
  [modalsKeys.editRefillSettings]: EditRefillSettings,
  [modalsKeys.addChargingStation]: AddChargingStation,
  [modalsKeys.addExistingCanister]: AddExistingCanister,
  [modalsKeys.addCanistersToChargingStation]: AddCanistersToCS,
  [modalsKeys.connectToPlug]: ConnectToPlug,
  [modalsKeys.changeCanisterType]: ChangeCanisterType,
};

export default modals;
