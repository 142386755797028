export const TOAST_NOTIFICATIONS_ACTIONS = {
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
  HIDE_OR_SHOW_TOAST: 'HIDE_OR_SHOW_TOAST',
};

/**
 * @typedef {{ id: string, show?: boolean } & import('ui').AlertProps} Toast
 *
 * @typedef {Object} ToastNotificationsState
 * @property {Array.<Toast>} toasts
 */

/** @type {ToastNotificationsState}  */
const DEFAULT_STATE = {
  toasts: [],
};

/**
 *
 * @param {ToastNotificationsState} state
 *
 * @param {Object} action
 * @param {string} action.type
 * @param {string?} action.toastId
 * @param {import('ui').AlertProps?} action.toast
 *
 * @returns {ToastNotificationsState}
 */
const toastNotificationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case TOAST_NOTIFICATIONS_ACTIONS.ADD_TOAST: {
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    }
    case TOAST_NOTIFICATIONS_ACTIONS.REMOVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter(({ id }) => id !== action.toastId),
      };
    }
    case TOAST_NOTIFICATIONS_ACTIONS.HIDE_OR_SHOW_TOAST: {
      return {
        ...state,
        toasts: state.toasts.map((toast) => ({
          ...toast,
          show: toast.id === action.toastId ? !toast.show : toast.show,
        })),
      };
    }
    default: {
      return state;
    }
  }
};

export default toastNotificationsReducer;
