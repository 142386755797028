import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  InputBase,
  Button,
  Icon,
  IconNames,
} from 'ui';
import { useTranslation, Trans } from 'react-i18next';
import { styled } from '@stitches/react';

const SearchCanisterInput = styled('div', {
  maxWidth: '346px',
});

const CanistersListContainer = styled('div', {
  maxWidth: '200px',
});

const CanistersListContainerLabel = styled('label', {
  height: '40px',
});

const AddCanisters = (props) => {
  const {
    onCancel,
    onSubmit,
    canisters,
  } = props;

  const { t } = useTranslation();
  const [filter, setfilter] = useState('');
  const [selectedCanisters, setSelectedCanisters] = useState([]);

  const handleCheckbox = (id, e) => {
    const checked = e.target.value;

    if (checked && !selectedCanisters.includes(id)) {
      setSelectedCanisters([...selectedCanisters, id]);
    } else {
      setSelectedCanisters(
        selectedCanisters.filter((canisterID) => canisterID !== id),
      );
    }
  };

  const handleSelectAll = (e) => {
    const checked = e.target.value;
    if (checked && selectedCanisters.length === canisters.length) {
      setSelectedCanisters([]);
    } else {
      setSelectedCanisters(canisters.map((canister) => canister.id));
    }
  };

  const handleSubmit = () => onSubmit(selectedCanisters);

  const filteredCanisters = filter === '' ? canisters : canisters.filter((canister) => {
    const matchID = canister.id.toLowerCase().includes(filter.toLowerCase());
    const matchName = canister.name.toLowerCase().includes(filter.toLowerCase());

    return matchID || matchName;
  });

  return (
    <>
      <div className="text-lg text-gray-900 font-medium mt-6">
        {t('modals.addChargingStation.steps.addCanisters.title')}
      </div>
      <div className="text-sm text-gray-500 mt-2">
        {t('modals.addChargingStation.steps.addCanisters.subtitle')}
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center h-5 w-36">
          <input
            id="selectAll"
            type="checkbox"
            checked={selectedCanisters.length === canisters.length}
            onChange={handleSelectAll}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
          <label htmlFor="selectAll" className="text-sm ml-4">
            {t('modals.addChargingStation.steps.addCanisters.selectAll', { qty: canisters.length })}
          </label>
        </div>
        <SearchCanisterInput className="search-canister-input flex w-full pl-3 flex w-full">
          <InputBase
            className="font-normal"
            type="text"
            placeholder={t('modals.addChargingStation.steps.addCanisters.searchCanisters')}
            value={filter}
            onChange={(e) => setfilter(e.target.value)}
            innerLabel={(
              <Icon
                icon={IconNames.search}
                size="14px"
              />
            )}
          />
        </SearchCanisterInput>
      </div>

      <CanistersListContainer className="canisters-list-container overflow-y-auto mt-4">
        {filteredCanisters.map(({ id, name }, index) => (
          <CanistersListContainerLabel
            key={id}
            htmlFor={id}
            className={clsx(
              index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              'flex items-center text-sm',
            )}
          >
            <input
              id={id}
              data-testid="select-canisters"
              type="checkbox"
              checked={selectedCanisters.includes(id)}
              onChange={handleCheckbox.bind(this, id)}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
            <div className="ml-4">
              <Trans
                // i18nKey="modals.addChargingStation.steps.addCanisters.canister"
                values={{ name, id }}
                components={{ span: <span className="text-gray-500" /> }}
              >
                {t('modals.addChargingStation.steps.addCanisters.canister')}
              </Trans>
            </div>
          </CanistersListContainerLabel>
        ))}
      </CanistersListContainer>

      <div className="bg-blue-50 rounded-md flex p-4 mt-6">
        <Icon
          icon={IconNames.infoCircle}
          className="ml-2"
          size="14px"
        />
        <div className="text-sm text-blue-800 font-medium ml-4">
          {t('modals.addChargingStation.steps.addCanisters.info')}
        </div>
      </div>

      <div className="mt-5 sm:flex sm:flex-row-reverse">
        <Button className="w-full sm:ml-3 sm:w-auto" type="button" data-testid="submit-button" variant="primary" onClick={handleSubmit}>
          {t('common.continue')}
        </Button>
        <Button className="mt-3 w-full sm:mt-0 sm:w-auto" type="button" data-testid="cancel-button" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

AddCanisters.defaultProps = {
  canisters: [],
};

AddCanisters.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  canisters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default AddCanisters;
