import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openModal, modalsKeys } from '@components/Modal';

const OPTIONS_ID = {
  'deposit-cycles': 'deposit-cycles',
  'change-canister-name': 'change-canister-name',
  'upgrade-wasm-module': 'upgrade-wasm-module',
  'edit-computation-capacity': 'edit-computation-capacity',
  'start-canister': 'start-canister',
  'stop-canister': 'stop-canister',
  'delete-canister': 'delete-canister',
  'update-canister-type': 'update-canister-type',
};

const ACTIONS = {
  [OPTIONS_ID['stop-canister']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.stopCanister, props)),
  [OPTIONS_ID['deposit-cycles']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.depositCycles, props)),
  [OPTIONS_ID['start-canister']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.stopCanister, props)),
  [OPTIONS_ID['delete-canister']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.deleteCanister, props)),
  [OPTIONS_ID['upgrade-wasm-module']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.upgradeWASM, props)),
  [OPTIONS_ID['change-canister-name']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.changeCanisterName, props)),
  [OPTIONS_ID['update-canister-type']]: (dispatch, props = {}) => dispatch(openModal(modalsKeys.changeCanisterType, props)),
};

const useMenuOptions = (canisters, balance) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const defaultAction = (canister) => {
    ACTIONS['deposit-cycles'](dispatch, {
      balance,
      canisters,
      selectedCanisterId: canister.id,
      ...canister,
    });
  };

  const handleOptionClick = (id, canister, closeMenu) => {
    closeMenu();

    const modalProps = {
      balance,
      canisters,
      selectedCanisterId: canister.id,
      ...canister,
    };

    ACTIONS[id](dispatch, modalProps);
  };

  const getOptions = (canister) => {
    const { canisterStatus } = canister;
    const isRunning = canisterStatus === 'running';
    const key = isRunning ? 'stop' : 'start';

    return [
      [
        {
          id: OPTIONS_ID['deposit-cycles'],
          label: t('canisters.table.menuOptions.depositCycles'),
          disabled: false,
          className: null,
        },
        {
          id: OPTIONS_ID['change-canister-name'],
          label: t('canisters.table.menuOptions.changeCanisterName'),
          disabled: false,
          className: null,
        },
        {
          id: OPTIONS_ID['update-canister-type'],
          label: t('canisters.table.menuOptions.updateCanisterType'),
          disabled: false,
          className: null,
        },
      ],
      [
        {
          id: OPTIONS_ID['upgrade-wasm-module'],
          label: t('canisters.table.menuOptions.upgradeWASM'),
          disabled: true,
          className: null,
        },
        {
          id: OPTIONS_ID['edit-computation-capacity'],
          label: t('canisters.table.menuOptions.editComputation'),
          disabled: true,
          className: null,
        },
      ],
      [
        {
          id: `${key}-canister`,
          label: t(`canisters.table.menuOptions.${key}Canister`),
          disabled: true,
          className: null,
        },
        {
          id: OPTIONS_ID['delete-canister'],
          label: t('canisters.table.menuOptions.deleteCanister'),
          disabled: false,
          className: 'text-red-800 hover:text-red-800',
        },
      ],
    ];
  };

  return [getOptions, handleOptionClick, defaultAction];
};

export default useMenuOptions;
