import { Principal } from '@dfinity/principal';
import { CANISTER_NAME_MAX_LENGTH, CANISTER_ID_MAX_LENGTH } from '@constants';
import { formatToCycles } from '@utils/formats';
import { readFileAsync } from '@utils/file';

export default {};

export const hasValidLength = (text, len = 0) => typeof text === 'string' && text.length <= len;

export const validateCanisterId = async (canisterId) => {
  let response = {
    hasError: false,
    errorMessage: '',
  };

  try {
    if (!canisterId) {
      throw Error('Oops! Canister id should not be empty');
    }

    if (typeof canisterId !== 'string') {
      throw Error('Oops! Canister id is not valid.');
    }

    if (!hasValidLength(canisterId, CANISTER_ID_MAX_LENGTH)) {
      throw Error(`Oops! Length of Canister id should not be greater than ${CANISTER_ID_MAX_LENGTH}`);
    }

    if (!(canisterId === Principal.fromText(canisterId).toString())) {
      throw Error('Oops! Not a valid principal');
    }
  } catch (error) {
    response = { hasError: true, errorMessage: error.message };
  }

  return response;
};

export const validateCanisterName = async (canisterName) => {
  let response = {
    hasError: false,
    errorMessage: '',
  };

  try {
    if (!canisterName) {
      throw Error('Oops! Canister name should not be empty');
    }

    if (typeof canisterName !== 'string') {
      throw Error('Oops! Canister name is not valid.');
    }

    if (!hasValidLength(canisterName, CANISTER_NAME_MAX_LENGTH)) {
      throw Error(`Oops! Length of Canister name should not be greater than ${CANISTER_NAME_MAX_LENGTH}`);
    }
  } catch (error) {
    response = { hasError: true, errorMessage: error.message };
  }

  return response;
};

// Parse and validate canister details
export const parseExistingCanister = async (canister) => {
  let validationDetails = {
    hasError: false,
    errorMessage: '',
    principal: '',
  };

  try {
    if (!canister?.name) {
      throw Error('Oops! Canister name should not be empty');
    }

    if (typeof canister.name !== 'string') {
      throw Error('Oops! Canister name is not valid.');
    }

    if (!hasValidLength(canister.name, CANISTER_NAME_MAX_LENGTH)) {
      throw Error(`Oops! Length of Canister name should not be greater than ${CANISTER_NAME_MAX_LENGTH}`);
    }

    if (!canister?.id) {
      throw Error('Oops! Canister id should not be empty');
    }

    if (typeof canister.id !== 'string') {
      throw Error('Oops! Canister id is not valid.');
    }

    if (!hasValidLength(canister.id, CANISTER_ID_MAX_LENGTH)) {
      throw Error(`Oops! Length of Canister id should not be greater than ${CANISTER_ID_MAX_LENGTH}`);
    }

    // Principal will throw error if any while parsing canister id
    const principal = Principal.fromText(canister.id);
    validationDetails = { ...validationDetails, principal };
  } catch (error) {
    validationDetails = { ...validationDetails, hasError: true, errorMessage: error.message };
  }

  return { ...canister, ...validationDetails };
};

export const parseCanisterDetails = async (canisterDetails) => {
  let response = {
    hasError: false,
    errorMessage: '',
    name: '',
    wasmFile: null,
    cycles: 0,
    subnet: '',
  };

  try {
    const {
      hasError: canisterNameValidationFailed,
      errorMessage: canisterNameErrorMessage,
    } = await validateCanisterName(canisterDetails.name);

    if (canisterNameValidationFailed) {
      throw Error(canisterNameErrorMessage);
    }

    const { name } = canisterDetails;
    response = { ...response, name };

    const {
      hasError: cyclesTokenValidationFailed,
      errorMessage: cyclesTokenErrorMessage,
      cycles,
    } = await formatToCycles(canisterDetails.initialCyclesToken);

    if (cyclesTokenValidationFailed) {
      throw Error(cyclesTokenErrorMessage);
    }

    response = { ...response, cycles };

    if (!canisterDetails?.subnet) {
      throw Error('Oops! Subnet should not be empty');
    }

    if (typeof canisterDetails.subnet !== 'string') {
      throw Error('Oops! Subnet is not valid.');
    }

    const { subnet } = canisterDetails;
    response = { ...response, subnet };

    if (!canisterDetails?.wasmFile) {
      throw Error('Oops! Please upload wasm file');
    }

    if (typeof canisterDetails.wasmFile !== 'object' || canisterDetails.wasmFile.type !== 'application/wasm') {
      throw Error('Oops! WASM file is not valid.');
    }

    // TODO: investigate types, as wasmfile.arrayBuffer failed
    // expected wasm_module is Uint8Array
    // const wasmFile = await canisterDetails.wasmFile.arrayBuffer();
    let wasmFile;

    try {
      wasmFile = await readFileAsync(canisterDetails.wasmFile);
      wasmFile = Array.from(wasmFile.values());
    } catch (err) {
      throw Error(`Oops! Fail to read WASM file ${err}`);
    }

    response = { ...response, wasmFile };
  } catch (error) {
    response = { ...response, hasError: true, errorMessage: error.message };
  }

  return response;
};
