import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import { openModal, modalsKeys } from '@components/Modal';
import { SUBNETS } from '@constants';

import { Button } from 'ui';

function AddCanisterBtn({
  t,
  datatestid,
  disableDropdown,
}) {
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);
  const [showAddCanisterDropdown, setAddCanisterDropdown] = React.useState(false);
  const [user] = useSelector((state) => ([
    state.user,
  ]));

  const handleOptionOnClick = (event) => {
    event.preventDefault();
    setAddCanisterDropdown(false);
    const { id } = event.target;

    if (id === 'add-canister-new') {
      dispatch(openModal(modalsKeys.addCanister, {
        balance: user.user.balance,
        subnets: SUBNETS,
      }));
      return;
    }

    if (id === 'add-canister-existing') {
      dispatch(openModal(modalsKeys.addExistingCanister));
    }
  };

  React.useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef && !containerRef.current.contains(event.target) && showAddCanisterDropdown) {
        setAddCanisterDropdown(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [showAddCanisterDropdown, containerRef]);

  return (
    <div ref={containerRef} className="relative">
      <Button
        size="sm"
        variant="primary"
        type="button"
        aria-label="Options Menu"
        data-testid={datatestid}
        aria-haspopup="true"
        aria-expanded={showAddCanisterDropdown}
        onClick={(event) => {
          event.preventDefault();

          if (!disableDropdown) {
            setAddCanisterDropdown((prevState) => !prevState);
            return;
          }

          dispatch(openModal(modalsKeys.addCanister, {
            balance: 208.5,
          }));
        }}
      >
        {t('canisters.add.label')}
        {
          !disableDropdown && (
            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          )
        }
      </Button>
      {
        !disableDropdown && (
          <Transition
            show={showAddCanisterDropdown}
            role="menu"
            data-testid="dropDownMenu"
            aria-orientation="vertical"
            aria-labelledby="btn-menu"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
          >
            <div className="bg-white rounded-md shadow-xs">
              <ul
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="btn-menu"
              >
                <li>
                  <button
                    type="button"
                    data-testid="dropdownmenu"
                    id="add-canister-new"
                    className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 w-full text-left"
                    onClick={handleOptionOnClick}
                  >
                    {t('canisters.add.new')}
                  </button>
                </li>
              </ul>
            </div>
          </Transition>
        )
      }
    </div>
  );
}

AddCanisterBtn.defaultProps = {
  disableDropdown: false,
  datatestid: '',
};

AddCanisterBtn.propTypes = {
  t: PropTypes.func.isRequired,
  disableDropdown: PropTypes.bool,
  datatestid: PropTypes.string,
};

export default AddCanisterBtn;
