import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getXTCMarketValue, XTC_CURRENCY_ABBR } from '@utils/xtc';
import { formatBalance } from '@utils/formats';
import { isValidBalance } from '@utils/balance';
import {
  TextCell,
  NameCell,
  TypeCell,
  ActionCell,
  AddressCell,
} from 'ui';

import { styled, keyframes } from '@stitches/react';
import { useAuth } from '../../../../contexts/AuthContext';
import { getCanisterBalanceById } from '../../../../actions';

const opacityAnim = keyframes({
  '0%': { opacity: 0.4 },
  '86%': { opacity: 0.9 },
  '100%': { opacity: 1 },
});

const TextCellWithAnimation = styled(TextCell, {
  opacity: 1,

  variants: {
    loading: {
      true: {
        animation: `${opacityAnim} 1s linear infinite`,
      },
    },
  },
});

const TableRow = styled('tr', {
  '& > td': {
    height: '52px',
    paddingTop: 0,
    paddingBottom: 0,
  },

  variants: {
    disabled: {
      true: {
        color: '#f7f7f7',
      },
    },
  },
});

const RenderRow = ({
  i18n,
  data,
  disabled,
  menuOptions,
  onActionClickOption,
}) => {
  const {
    id,
    name,
    type,
    status,
    network,
    chargingStationId,
    highlighted,
    balance,
    loadingBalance,
  } = data;
  const { projectActor } = useAuth();

  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(getCanisterBalanceById({ canisterId: id, projectActor }));
  }, [projectActor]);

  return (
    <TableRow
      // onDoubleClick={() => history.push(`canisters/${id}`)}
      className={clsx(
        'border-t border-gray-200',
        highlighted && 'animate-new-row',
      )}
      disabled={disabled}
    >
      <NameCell name={name} status={status} disabled={disabled} />
      <AddressCell
        address={id}
        network={network}
        chargingStation={id}
      />
      <TextCellWithAnimation
        textContent={isValidBalance(balance) ? `${balance} ${XTC_CURRENCY_ABBR}` : '-'}
        secondaryContent={
          (
            isValidBalance(balance)
            && `$${formatBalance(getXTCMarketValue(balance), 2)}`
          ) || ''
        }
        align="right"
        boldText
        loading={loadingBalance}
      />
      {
        type
          ? <TypeCell>{type}</TypeCell>
          : (
            <TextCellWithAnimation
              textContent="-"
              align="right"
              boldText
              loading={loadingBalance}
            />
          )
      }
      <ActionCell
        chargingStation={{
          title: i18n.chargingStation,
          address: chargingStationId,
          disabled: !chargingStationId,
        }}
        menuOptions={menuOptions(data)}
        onClickOption={(closeMenu, optionId) => onActionClickOption(optionId, data, closeMenu)}
      />
    </TableRow>
  );
};

RenderRow.defaultProps = {
  menuOptions: () => {},
  disabled: false,
};

RenderRow.propTypes = {
  onActionClickOption: PropTypes.func.isRequired,
  menuOptions: PropTypes.func,
  disabled: PropTypes.bool,
  i18n: PropTypes.shape({
    chargingStation: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['running', 'stopped']),
    id: PropTypes.string,
    chargingStationId: PropTypes.string,
    network: PropTypes.string,
    cyclesUsed: PropTypes.string,
    totalTx: PropTypes.string,
    updated: PropTypes.string,
    type: PropTypes.string,
    created: PropTypes.string,
    memoryUsage: PropTypes.number,
    highlighted: PropTypes.bool,
    balance: PropTypes.number,
    loadingBalance: PropTypes.bool,
  }).isRequired,
};

export default RenderRow;
