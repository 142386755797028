import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { styled, keyframes } from '@stitches/react';

import {
  Select,
  MenuItem,
  Button,
} from 'ui';

const scaleAnim = keyframes({
  '0%': { transform: 'scale(1.0)' },
  '50%': { transform: 'scale(1.08)' },
  '100%': { transform: 'scale(1.0)' },
});

const CodeContent = styled('td', {
  height: 'max-content',
});

const ButtonWithAnimation = styled(Button, {
  '&:active': {
    animation: `${scaleAnim} 1s linear`,
  },
});

const languages = [
  {
    name: 'Motoko',
    id: 'motoko',
  },
  {
    name: 'Rust',
    id: 'rust',
  },
];

const CodeBox = ({ code }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState(languages[0].id);

  const languagesOptions = languages.map((lang) => (
    <MenuItem value={lang.id}>
      <span>{lang.name}</span>
    </MenuItem>
  ));

  const lineMatcher = /\r\n|\n\r|\n|\r/g;
  const codeLines = code.replace(lineMatcher, '\n').split('\n');

  const lineCountElements = [];

  const lineCodeElements = codeLines.reduce((accumulator, currentLine, index) => {
    lineCountElements.push((
      <p>
        {index + 1}
      </p>
    ));

    if (currentLine.trim() === '') {
      accumulator.push(<br />);
    } else {
      accumulator.push((
        <p className="whitespace-nowrap">{currentLine}</p>
      ));
    }

    return accumulator;
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between px-6 py-4 bg-gray-50 text-gray-500 items-center">
        <p className="font-medium text-base">{t('codeBlock.title')}</p>
        <div className="flex flex-row items-center">
          <Select
            value={language}
            onChange={(id) => setLanguage(id)}
          >
            {languagesOptions}
          </Select>
          <ButtonWithAnimation
            className="mt-1 ml-2"
            onClick={() => {
              navigator.clipboard.writeText(code);
            }}
          >
            <span>{t('codeBlock.copyCode')}</span>
          </ButtonWithAnimation>
        </div>
      </div>
      <div className="m-5 border box-content rounded flex flex-row border-gray-300 h-full overflow-y-auto">
        <CodeContent className="rounded-l bg-gray-100 text-gray-700 w-16 items-end flex flex-col p-3 leading-7">
          {lineCountElements}
        </CodeContent>
        <CodeContent className="bg-gray-50 text-gray-600 w-full rounded-r overflow-x-auto flex flex-col p-3 leading-7">
          {lineCodeElements}
        </CodeContent>
      </div>
    </>
  );
};

CodeBox.propTypes = {
  code: PropTypes.string.isRequired,
};

export default CodeBox;
