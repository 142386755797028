import React from 'react';
import { LoadingCell } from 'ui';

const LoadingRow = () => (
  <tr className="border-t border-gray-200 render-row">
    <LoadingCell />
    <LoadingCell />
    <LoadingCell />
    <LoadingCell />
    <LoadingCell />
  </tr>
);

export default LoadingRow;
