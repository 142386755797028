import { OPEN_MODAL, CLOSE_MODAL } from '../../reducers/modal';

export const openModal = (modal, props = {}, size = 'lg') => (dispatch) => dispatch({
  type: OPEN_MODAL,
  payload: {
    modal,
    props,
    size,
  },
});

export const closeModal = () => (dispatch) => dispatch({
  type: CLOSE_MODAL,
});
