import React from 'react';
import { Button } from 'ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const StopContainer = (props) => {
  const {
    canisterId,
    closeModal,
    canisterName,
  } = props;

  const { t } = useTranslation();

  const stopCanister = () => {
    // TODO add integration to stop canister using the canisterId
    // eslint-disable-next-line no-console
    console.log('stopping canister', canisterId);
    closeModal();
  };

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            {t('modals.stopCanister.title')}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t('modals.stopCanister.confirmationText')}
            </p>
          </div>
          <div className="mt-7">
            <p className="text-sm text-gray-500">
              <span className="text-gray-900 font-medium">{canisterName}</span>
              &nbsp;
              {`(${canisterId})`}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-10 sm:flex sm:flex-row-reverse">
        <Button
          type="button"
          data-testid="stop-canister"
          variant="danger"
          onClick={stopCanister}
          className="w-full sm:ml-3 sm:w-auto"
        >
          {t('common.stopCanister')}
        </Button>
        <Button
          type="button"
          data-testid="close-modal"
          onClick={closeModal}
          className="mt-3 w-full sm:mt-0 sm:w-auto"
        >
          {t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

StopContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  canisterId: PropTypes.string.isRequired,
  canisterName: PropTypes.string.isRequired,
};

export default StopContainer;
