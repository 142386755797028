export const FETCH_CANISTERS_ACTIONS = {
  SUCCESS: 'FETCH_CANISTERS_SUCCESS',
  LOADING: 'FETCH_CANISTERS_LOADING',
  ERROR: 'FETCH_CANISTERS_ERROR',
};

export const ADD_CANISTER_ACTIONS = {
  SUCCESS: 'ADD_CANISTER_SUCCESS',
};

export const UPDATE_CANISTER_BY_ID = 'UPDATE_CANISTER_BY_ID';

export const REMOVE_CANISTER_BY_ID = 'REMOVE_CANISTER_BY_ID';

const defaultState = {
  canisters: [],
  fetchCanisters: {
    loading: true,
    error: false,
    ids: [],
    total: 0,
  },
  canistersByChargingStationIds: {
    loading: true,
    error: false,
    chargingStationIds: {
      // the id of the charging station can be the object's properties
      // and each id can have a list of canister ids
    },
    total: 0,
  },
};

const canistersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REMOVE_CANISTER_BY_ID: {
      const updatedCanisterIndex = state.canisters.findIndex((canister) => (
        (canister.id === action.canisterId)
      ));
      if (updatedCanisterIndex === -1) {
        return state;
      }
      return {
        ...state,
        canisters: [
          ...state.canisters.slice(0, updatedCanisterIndex),
          ...state.canisters.slice(updatedCanisterIndex + 1, state.canisters.length),
        ],
      };
    }
    case UPDATE_CANISTER_BY_ID: {
      const updatedCanisterIndex = state.canisters.findIndex((canister) => (
        (canister.id === action.canisterId)
      ));
      if (updatedCanisterIndex === -1) {
        return state;
      }
      const newCanister = {
        ...state.canisters[updatedCanisterIndex],
        ...action.canister,
      };
      return {
        ...state,
        canisters: [
          ...state.canisters.slice(0, updatedCanisterIndex),
          newCanister,
          ...state.canisters.slice(updatedCanisterIndex + 1, state.canisters.length),
        ],
      };
    }
    case ADD_CANISTER_ACTIONS.SUCCESS: {
      return {
        ...state,
        canisters: [
          {
            ...action.canister,
            highlighted: true,
          },
          ...state.canisters,
        ],
        fetchCanisters: {
          ids: [
            action.canister.id,
            ...state.fetchCanisters.ids,
          ],
          total: state.fetchCanisters.total + 1,
        },
      };
    }
    case FETCH_CANISTERS_ACTIONS.SUCCESS: {
      return {
        ...state,
        canisters: action.canisters,
        fetchCanisters: {
          // TODO: what happens if canisters is not an array or empty?
          ids: action.canisters.map((canister) => canister.id),
          total: action.total,
          loading: false,
          error: false,
        },
      };
    }
    case FETCH_CANISTERS_ACTIONS.LOADING: {
      return {
        ...state,
        fetchCanisters: {
          ...state.fetchCanisters,
          loading: true,
          error: false,
        },
      };
    }
    case FETCH_CANISTERS_ACTIONS.ERROR: {
      return {
        ...state,
        fetchCanisters: {
          ...state.fetchCanisters,
          loading: false,
          error: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default canistersReducer;
